/****** Style css ****************************/

$slick-font-path: "~slick-carousel/slick/fonts/";
$slick-loader-path: "~slick-carousel/slick/";

@import "~slick-carousel/slick/slick", "~slick-carousel/slick/slick-theme";

/****** Style css ****************************/

@font-face {
  font-family: "Gotham HTF Bold 2";
  src: url("/fonts/GothamHTF-Bold.eot"); /* IE9*/
  src: url("/fonts/GothamHTF-Bold.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("/fonts/GothamHTF-Bold.woff2") format("woff2"),
    /* chrome、firefox */ url("/fonts/GothamHTF-Bold.woff") format("woff"),
    /* chrome、firefox */ url("/fonts/GothamHTF-Bold.ttf") format("truetype"),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
      url("/fonts/GothamHTF-Bold.svg#Gotham HTF Bold 2") format("svg"); /* iOS 4.1- */
}

@font-face {
  font-family: "Gotham HTF Medium 2";
  src: url("/fonts/GothamHTF-Medium.eot"); /* IE9*/
  src: url("/fonts/GothamHTF-Medium.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("/fonts/GothamHTF-Medium.woff2") format("woff2"),
    /* chrome、firefox */ url("/fonts/GothamHTF-Medium.woff") format("woff"),
    /* chrome、firefox */ url("/fonts/GothamHTF-Medium.ttf") format("truetype"),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
      url("/fonts/GothamHTF-Medium.svg#Gotham HTF Medium 2") format("svg"); /* iOS 4.1- */
}

@font-face {
  font-family: "gotham-mediumgotham-medium";
  src: url("/fonts/gothmmed-webfont.woff2") format("woff2"),
    url("/fonts/gothmmed-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "gotham-lightgotham-light";
  src: url("/fonts/gothmlig-webfont.woff2") format("woff2"),
    url("/fonts/gothmlig-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

html {
  height: 100%;
}

body {
  font-family: "Gotham HTF Bold 2";
  font-size: 16px;
  line-height: 1.4;
  overflow-x: hidden;
  background: #fdc627;
  background: -moz-radial-gradient(
    circle,
    rgba(255, 255, 255, 0.6) 0%,
    rgba(253, 198, 39, 1) 35%
  );
  background: -webkit-radial-gradient(
    circle,
    rgba(255, 255, 255, 0.6) 0%,
    rgba(253, 198, 39, 1) 35%
  );
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 0.6) 0%,
    rgba(253, 198, 39, 1) 35%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#fdc627",GradientType=1);

  background-repeat: no-repeat;
  background-color: rgba(253, 198, 39, 1);
}

ul.style-none {
  list-style: none;
  padding: 0;
  margin: 0;
}

/** welcome **/

body.height-fix {
  height: 100%;
}

.height-full {
  height: 100%;
}

.welcome .container {
  max-width: 650px;
  height: 100%;
  display: table;
}

.welcome .container > .row {
  height: 100%;
}

.welcome-wrapper {
  height: 100%;
  display: table;
}

.welcome-content {
  display: table-cell;
  vertical-align: middle;
  height: 100vh;
  overflow: hidden;
  padding: 0 15px;

}

.idfc-logo-welcome {
  text-align: center;
  padding-top: 20px;
}

.idfc-logo-welcome img {
  width: 90%;
}

.image-full img {
  width: 100%;
}

a.lets-start {
  color: #fff;
  background: #f1666b;
  border-radius: 40px;
}

a.lets-start:hover,
a.lets-start:focus {
  color: #ffffff;
}

.already-link {
  font-style: italic;
  margin-left: 10px;
  font-size: 14px;
  font-family: "gotham-mediumgotham-medium";
}

.already-link a {
  color: #000;
  text-decoration: underline;
}

/** Header style **/

.logo {
  margin-left: 2.8vw;
}

.header-corner {
  background: #fff;
  padding: 0 20px;
  height: 100px;
  border-radius: 0 0 0 40px;
  position: relative;
}

.right-corner-bottom {
  width: 50px;
  height: 50px;
  background: #fff;
  position: absolute;
  bottom: -50px;
  right: 0;
  z-index: -1;
}

.right-corner-bottom:after {
  width: 50px;
  height: 50px;
  background: #fdc627;
  border-radius: 0 50px 0 0;
  position: absolute;
  left: 0;
  top: 0;
  content: "";
}

#header .container {
  padding: 0;
  margin-right: 0px;
}

.nav-right-icon {
  display: inline-block;
  cursor: pointer;
  margin-right: 5%;
}

.bar1,
.bar2,
.bar3 {
  width: 42px;
  height: 5px;
  background-color: #333;
  margin: 8px 0;
  transition: 0.4s;
  border-radius: 10px;
}

.nav-right-icon .bar1 {
  width: 24px;
}

.nav-right-icon .bar2 {
  width: 35px;
}

.text-subtitle {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  color: #8c5e5e;
  font-size: 26px;
}

/******* Left Menu Style *******/

.left-nav-block {
  padding: 0;
  margin-left: -5%;
  margin-top: 30px;
}

.left-nav-menu {
  background: #ffffff;
  padding: 0;
  border-radius: 0 50px 50px 0;
}

.left-nav-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.left-nav-menu a {
  color: #4c4c4c;
  font-size: 1vw;
  text-transform: uppercase;
  padding: 30px 30px 30px 8.6vw;
  display: block;
  text-decoration: none;
  position: relative;
}

.left-bar-block {
  width: 100%;
}

.left-nav-menu a:before {
  content: "";
  position: absolute;
  bottom: 0;
  height: 1px;
  width: 40%;
  right: 3.8vw;
  border-bottom: 1px solid #facccd;
}

.left-nav-menu li:last-child a:before {
  display: none;
}

.left-nav-menu a:hover:after,
.left-nav-menu a.current:after {
  content: "";
  position: absolute;
  right: -20px;
  top: 50%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 20px 0 20px 23px;
  border-color: transparent transparent transparent #f79448;
  transform: translate(0, -50%);
}

.left-nav-menu li:first-child a:hover:after,
.left-nav-menu li:first-child a.current:after {
  margin-top: 10px;
}

.left-nav-menu li:last-child a:hover:after,
.left-nav-menu li:last-child a.current:after {
  margin-top: -10px;
}

.left-nav-menu a:hover,
.left-nav-menu a.current {
  background: #f79548;
  color: #ffffff;
}

.left-nav-menu li:first-child a:hover,
.left-nav-menu li:first-child a.current {
  border-radius: 0 50px 0 0;
}

.left-nav-menu li:last-child a:hover,
.left-nav-menu li:last-child a.current {
  border-radius: 0 0 50px 0;
}

.left-nav-menu a:hover:before,
.left-nav-menu a.current:before {
  border: 0;
}

span.menu-icon {
  background: url("/images/split-img.png") no-repeat -14px 0;
  width: 35px;
  height: 35px;
  display: block;
  float: left;
  margin-top: -7px;
  margin-right: 15px;
}

span.menu-icon.life-i {
  background-position: -12px -9px;
}

.hide{
  visibility: hidden !important;
}

 
span.menu-icon.purchase-i {
  background-position: -12px -121px;
}

span.menu-icon.invest-i {
  background-position: -9px -225px;
}

span.menu-icon.loans-i {
  background-position: -6px -333px;
}

span.menu-icon.personal-i {
  background-position: -7px -440px;
}

span.menu-icon.insurance-i {
  background-position: -7px -548px;
}

a:hover span.menu-icon.life-i,
a.current span.menu-icon.life-i {
  background-position: -64px -9px;
}

a:hover span.menu-icon.purchase-i,
a.current span.menu-icon.purchase-i {
  background-position: -64px -121px;
}

a:hover span.menu-icon.invest-i,
a.current span.menu-icon.invest-i {
  background-position: -61px -225px;
}

a:hover span.menu-icon.loans-i,
a.current span.menu-icon.loans-i {
  background-position: -58px -333px;
}

a:hover span.menu-icon.personal-i,
a.current span.menu-icon.personal-i {
  background-position: -59px -440px;
}

a:hover span.menu-icon.insurance-i,
a.current span.menu-icon.insurance-i {
  background-position: -59px -548px;
}

/** Right side style ***/

.right-side {
  padding-right: 4%;
  padding-left: 0;
}

.profile-car {
  background: #f79649;
  display: flex;
  align-items: center;
  padding: 40px 40px 0;
  border-radius: 0 0 50px 50px;
  justify-content: space-between;
}

.profile-car span.number-count {
  color: #ffffff;
  font-size: 20px;
}

.profile-car span.number-count b {
  font-size: 33px;
  line-height: 0;
}

.in-exp {
  background: #4c4c4c;
  border-radius: 50px;
  padding: 10px 40px;
  margin-top: 20px;
  color: #fff;
  display: inline-block;
  text-align: center;
  width: 100%;
}

.in-exp > div {
  text-align: left;
  padding: 0 7%;
  display: inherit;
}

.in-exp > div > span {
  display: block;
}

span.text-bigger {
  font-size: 25px;
}

.in-exp > div.incomme {
  border-right: 1px solid #ffffff;
}

.ic-right-wrap {
  background: #ffffff;
  border-radius: 50px;
  padding: 10px;
  margin-top: 20px;
  cursor: pointer;
}

.ic-right-wrap li.ic-list:after {
  content: "";
  display: block;
  clear: both;
}

.ic-right-wrap li.ic-list {
  background: #fff;
  border-radius: 30px;
  padding: 30px 2vw;
  margin-top: -50px;
  height: 160px;
  display: flex;
  align-content: space-between;
}

.ic-right-wrap li.ic-list.card-block {
  background: #e5e5e5;
}

.ic-left-icon {
  background: url("/images/split-img.png") no-repeat 0 0;
  width: 40px;
  height: 35px;
  display: block;
  float: left;
  margin-right: 1.5vw;
}

.ic-left-icon.cash-i {
  background-position: -152px -11px;
}

.ic-left-icon.card-i {
  background-position: -152px -124px;
}

.ic-left-icon.investment-i {
  background-position: -152px -229px;
}

.ic-right-text {
  float: left;
}

.ic-right-text .ic-text {
  color: #3d3d3d;
  text-transform: uppercase;
}

.ic-right-text .ic-price {
  font-size: 25px;
}

.ic-right-wrap ul {
  margin-top: 60px;
}

.ic-right-wrap li.ic-list.invest-block {
  height: auto;
  display: flex;
}

.ic-right-wrap li.ic-list.cash-block {
  padding-top: 20px;
}

.ic-right-icon {
  background: url(/images/split-img.png) no-repeat -152px -332px;
  width: 40px;
  height: 35px;
  display: block;
  float: right;
  margin-right: 0;
  margin-top: 9px;
  margin-left: auto;
}

.right-bottom-man {
  margin-top: 20px;
  position: relative;
}

.right-bottom-man img {
  width: 9vw;
}

.tag-box {
  position: absolute;
  top: 0;
  width: 200px;
  padding: 34px;
  background: #f1666b;
  border-radius: 40px;
  right: 0;
  color: #ffffff;
  font-size: 23px;
  line-height: 27px;
}

.tag-box:after {
  content: "";
  position: absolute;
  left: -26px;
  top: 50%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 30px 30px;
  border-color: transparent transparent #f1666b transparent;
  transform: translate(0, -50%);
}

/*** Main style **/

.main {
  margin: 30px auto 0;
}

.left-main-block {
  padding-right: 0;
}

.main-wrapper {
  background: #ffffff;
  border-radius: 50px;
  padding: 30px;
  width: 100%;
}

.custom-style-bg{
  background: transparent;
  overflow: visible !important;
}

.home .main-wrapper {
  // background: transparent;
  // border-radius: 0;
  padding: 10px;
  overflow: hidden;
}

.carousel-wrapper {
  background: transparent;
  border-radius: 0;
  padding: 10px;
  width: 100%;
  min-height: 437px;
}

@media (min-width: 1920px){
  .carousel-wrapper{
    min-height: 521px;
  }
  .redeemed{
    bottom: -4px !important;
    right: 25px !important;
    // width: 30%;
  }
  
}


@media (max-width: 768px){
  .cube-container{
    top: 45% !important;
    left: 40% !important;
    zoom: .2 !important;
  }
  .all-done-section{
    min-height: 591px !important;
  }

  .bugsoverlay-popup .bugs-content ,.bugsoverlay-popup h2.title-heading-name{
    text-align: center;
    padding-right: 0  !important;
  }


 .bugs-content p{
    font-size: 13px !important;
    margin-bottom: 15px !important;
    max-width: 380px;
    margin: auto;
  }

  .bugsoverlay-popup .bugs-content .button-footer{
    padding-right: 0 !important;
    margin: auto;
    display: block;
  }

  img.bugs-man-stand{
    width: 90px !important;
    bottom: -60px !important;
  }

}

@media (max-width: 414px){
  .all-done-section{
    min-height: 419px !important;
  }

  .tab-block .tab-content{
    max-height: 50vh !important;
  }

  .paylater-tooltip {
    left: 30%;
    // top: 19%;
}



}

@media (max-width: 360px){
  .all-done-section{
    min-height: 367px !important;
  }
  #makeaninvestment label.invest-round span.invest-i-right {
    background-position: -2px 7px !important;
}

.view-agian{
  font-size: 14px !important;
}


}

.carousel-wrapper:after {
  display: block;
  content: "";
  clear: both;
}

.main.trans {
  background: transparent;
}

.home{
  height: 90vh;
}

.home .main {
  padding: 0;
  height: auto;
}

.banner-content {
  position: absolute;
  top: 5%;
  width: 50%;
  left: 5%;
}

.banner-content h1 {
  color: #404040;
  font-size: 41px;
}

.banner-content p {
  font-size: 20px;
  line-height: 22px;
  color: #4c4c4c;
}

.banner-content a.btn {
  background: #f1666b;
  border-radius: 40px;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  color: #fff;
  margin-top: 20px;
  margin-right: 5px;
  font-size: 11px;
  line-height: 11px;
  padding: 6px 17px;
}

.for-mobile {
  display: none;
}

.clear:after {
  display: block;
  content: "";
  clear: both;
}

/** Tab style **/

.tab-block .nav.nav-tabs {
  display: flex;
  background: #000;
  border-radius: 20px;
  align-items: center;
}

.tab-block .nav.nav-tabs li {
  width: 50%;
}

.tab-block .nav.nav-tabs li a {
  text-align: center;
  padding: 10px;
  border-radius: 20px;
  width: 100%;
  display: block;
  color: #ffffff;
  text-transform: uppercase;
  text-decoration: none;
  line-height: 18px;
  position: relative;
}

.tab-block .nav.nav-tabs li a.active {
  background: #f1666b;
}

.tab-block .nav.nav-tabs li a.active:after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 12px 12.5px 0 12.5px;
  border-color: #f1666b transparent transparent transparent;
  position: absolute;
  bottom: -12px;
  left: 20%;
}

.tab-block .tab-content {
  padding: 30px 20px 0;
  background: #fff;
}

.inv-row {
  display: flex;
  background: #feeec0;
  padding: 15px 40px;
  border-radius: 30px;
  align-items: center;
  margin-bottom: 30px;
}

.inv-row > span {
  width: 50%;
}

.text-inv {
  font-size: 18px;
  padding-right: 12%;
  line-height: 1.2;
}

.text-medium {
  font-family: "Gotham HTF Medium 2";
}

.inv-row > span.price {
  font-size: 25px;
}

.accordion-wrapper {
}

.panel.panel-default {
  padding: 20px 30px;
  background: #efefef;
  border-radius: 30px;
  margin-bottom: 30px;
}

.panel-heading {
  position: relative;
  display: flex;
  color: #f1666b;
  align-items: center;
  cursor: pointer;
}

.panel-heading > div {
  width: 50%;
}

.panel-heading .p-title {
  display: flex;
  align-items: center;
}

.panel-heading .price.inv-price {
  font-size: 25px;
}

.panel-heading h4 {
  font-size: 20px;
  line-height: 1.2;
  margin: 0;
  text-transform: uppercase;
}

.icon-s {
  background: url(/images/split-img.png) no-repeat -159px -434px;
  width: 35px;
  height: 35px;
  display: block;
  float: left;
  margin-right: 20px;
}
.icon-s.mf-i {
  background-position: -159px -434px;
}

.icon-s.deposits-i {
  background-position: -157px -501px;
}

.icon-s.stock-i {
  background-position: -156px -566px;
}

.icon-s.life-ins-i {
  background-position: -227px -230px;
  width: 48px;
  margin-left: -9px;
}

.icon-s.gen-ins-i {
  background-position: -236px -127px;
}

.icon-s.right-i {
  background-position: -231px -328px;
  margin-right: 10px;
}

.icon-s.remove-i {
  background-position: -231px -437px;
  margin-right: 10px;
}

.panel-heading[data-toggle="collapse"]:after {
  content: "";
  position: absolute;
  color: #b0c5d8;
  font-size: 18px;
  line-height: 22px;
  right: 10px;
  top: 2px;
  width: 30px;
  height: 30px;
  background: url(/images/split-img.png) no-repeat -258px -10px;
}
.panel-heading[data-toggle="collapse"].collapsed:after {
  background-position: -217px -10px;
}

.table-invesment ul li {
  display: flex;
  padding: 20px 0;
  font-size: 18px;
  font-family: "Gotham HTF Medium 2";
  border-top: 1px solid #f1666b;
  color: #3b3a3c;
  align-items: center;
}

.table-invesment ul li > div {
  width: 30%;
}

.table-invesment ul li > div.inv-title {
  width: 50%;
  padding-right: 20px;
}

span.redeem-i {
  width: 30px;
  height: 24px;
  background: url(/images/split-img.png) no-repeat -8px -619px;
  display: inline-block;
  margin-bottom: -6px;
  margin-right: 4px;
}

.inv-redeem {
  font-size: 13px;
  text-align: right;
}

button.redeem-btn {
  border: 0 !important;
  padding: 0;
}

button.redeem-btn:focus {
  border: 0 !important;
  outline: 0;
}

/*** Pop style ***/

.modal-content {
  border: 6px solid #fff;
  border-radius: 30px;
  outline: 0;
  box-shadow: 1px 3px 18px #333;
  background: #f1666b;
}

.modal-backdrop {
  background-color: transparent;
}

a.close {
  position: absolute;
  right: 50px;
  top: -21px;
  background: #000;
  border-radius: 45px;
  height: 40px;
  width: 40px;
  padding: 0;
  border: 4px solid #fff;
  font-size: 33px;
  color: #fff;
  opacity: 1;
  text-align: center;
  cursor: pointer;
  z-index: 3;
}

a.close:hover {
  color: #fff;
  text-decoration: none;
  opacity: 1 !important;
}

.modal-body {
  padding: 0;
  border-radius: 30px;
  overflow: hidden;
  font-family: "Gotham HTF Medium 2";
  font-size: 16px;
}

h5.title-redeem {
  color: #000;
  font-size: 20px;
  margin: 20px 30px 20px 70px;
  border-bottom: 1px solid #fff;
  padding-bottom: 7px;
  font-family: "Gotham HTF Medium 2";
  text-transform: uppercase;
}

.head-title-name {
  color: #fff;
  font-size: 25px;
  margin: 20px 30px 20px 70px;
  font-family: "Gotham HTF Medium 2";
  text-transform: uppercase;
}

.redeem-wrapper {
  display: flex;
  // height: 460px;
}

.align-middle {
  align-items: center;
}

.right-side-redeem {
  text-align: left;
  padding: 0 30px;
  color: #fff;
  width: 65%;
}

.right-side-redeem.bt-padding {
  padding: 30px;
}

h3.subheading-name {
  font-size: 20px;
  border-bottom: 1px solid #fff;
  padding-bottom: 10px;
}

h6.subtitle-redeem {
  color: #000;
  text-transform: uppercase;
  font-size: 20px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  background-color: #000;
  border: 4px solid #fff;
}

label.custom-control-label {
  line-height: 27px;
  font-size: 17px;
}

label.custom-control-label em {
  display: block;
  line-height: 15px;
  font-size: 17px;
  font-style: normal;
}

.form-content {
  border-bottom: 1px solid #fff;
  padding-bottom: 10px;
  // margin-bottom: 40px;
}

.radio.custom-radio {
  position: relative;
}

.radio.custom-radio .custom-control-input {
  z-index: 1;
}

.custom-control-label::before {
  border: 0;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  display: none;
}

.custom-control.custom-radio.custom-control-inline {
  margin-bottom: 30px;
  display: block;
}

.custom-control.custom-radio.custom-control-inline.part-of-inv {
  margin-bottom: 0 !important;
}

.input-field-block {
  margin-top: 20px;
  padding-right: 20%;
}

input.input-redeem {
  border: 0;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 8px;
  font-size: 20px;
  font-family: "Gotham HTF Medium 2";
  max-width: 300px;
  width: 100%;
}

.modal input.input-redeem {
  font-size: 16px;
}

em.error-text {
  color: #000;
  font-size: 17px;
  line-height: 19px !important;
  display: block;
}

.note-text {
  color: #000;
  font-size: 17px;
  line-height: 19px !important;
}

.btn-primary {
  color: #fff;
  background-color: #000;
  border: 0;
  border-radius: 10px;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 22px;
}

.btn-primary:hover,
.btn-primary:focus {
  color: #fff;
  background-color: #000 !important;
  border: 0 !important;
  outline: 0 !important;
  box-shadow: none !important;
}

.btn-secondary {
  color: #fff;
  background-color: transparent;
  border: 0;
}

.btn-secondary:hover,
.btn-secondary:focus {
  color: #fff !important;
  background-color: transparent !important;
  border: 0 !important;
  outline: 0 !important;
  box-shadow: none !important;
}

input:focus {
  outline: none !important;
}

.redeem-confirmation {
  padding: 50px 10px;
}

.redeem-confirmation .right-side-redeem {
  padding: 0 10px;
}

.redeem-success .redeem-wrapper {
  display: flex;
  align-items: center;
}

.modal-body p {
  font-size: 16px;
  font-family: "Gotham HTF Medium 2";
  line-height: 1.2;
  // margin-top: 2rem;
  margin-bottom: 2;
}

.button-footer {
  margin-bottom: 15px;
  display: flex;
  margin-top: 15px;
}

.button-footer button.btn {
  width: 50%;
  max-width: 200px;
}

.border-bottom {
  border-bottom: 1px solid #fff;
  padding-bottom: 20px;
}

.table-invesment ul li > div.invest-right {
  width: 50%;
  text-align: right;
  cursor: pointer;
}

div.invest-right {
  width: 50% !important;
  text-align: right !important;
  cursor: pointer;
}

label.invest-round {
  background: #fff;
  padding: 10px;
  border-radius: 15px;
  height: 10px;
  font-size: 14px;
  width: 110px;
  line-height: 3px;
  text-transform: uppercase;
  position: relative;
  text-align: center;
  padding-left: 17%;
  cursor: pointer;
  color: #3b3a3c;
  margin-bottom: 0;
  vertical-align: middle;
}

label.invest-round span.invest-i-right {
  width: 30px;
  height: 38px;
  background: url(/images/split-img.png) no-repeat -157px -635px;
  display: inline-block;
  position: absolute;
  left: 10px;
  top: -11px;
}

.left-side-image.float-left img {
	width:100%;
}

.make-aninvest-tab-content .modal-content {
  background: #bc8cbf;
}

/**** Repay ****/

.repay-block .panel-heading {
  cursor: default;
}

.paylater-tooltip{ width: 30px; position: absolute; left: 25%;  top: -80%; z-index: 1; }

.panel-heading > div.mid-col {
  width: 25%;
}

.panel-heading > div.right-col {
  width: 25%;
  text-align: right;
}

.panel-heading > div.wide-full {
  width: 100%;
}

.table-block ul li {
  display: flex;
  padding: 20px 0;
  font-size: 18px;
  font-family: "Gotham HTF Medium 2";
  border-top: 1px solid #f1666b;
  color: #3b3a3c;
  align-items: center;
  position: relative;
}

.table-block div.left-col {
  width: 50%;
  padding-right: 15px;
}

.table-block div.mid-col {
  width: 25%;
}

.mid-col.price {
  font-size: 25px;
  font-family: "Gotham HTF Medium 2";
}

.table-block div.wide-full {
  width: 100%;
}

.small-text {
  font-size: 13px;
}

.table-block li.border-no {
  border: 0;
}

label.invest-round span.repay-icon {
  width: 30px;
  height: 38px;
  background: url(/images/split-img.png) no-repeat -96px -635px;
  display: inline-block;
  position: absolute;
  left: 10px;
  top: -11px;
}

.text-in {
  padding-left: 20%;
}

.text-in.invest-text {
  padding-left: 0;
}

.repay-your-loans-modal-content .modal-content {
  background: #f79649;
}

.repay-your-loans-modal-content .confirmation {
  padding: 50px 10px 50px 0;
}

.bkg-gray {
  background: #efefef;
  border-radius: 50px;
  padding: 40px 50px;
}

.form-repay {
  padding-right: 20%;
}

.title-repay {
  font-size: 22px;
  color: #f1666b;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
}

.select-group {
  position: relative;
  margin-top: 10px;
  max-width: 300px;
}

.arrow-select {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  position: absolute;
  right: 20px;
  top: 10px;
}

.arrow-select.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.form-group select {
  border: 0;
  padding: 7px 10px 7px 10px;
  border-radius: 10px;
  margin-bottom: 8px;
  font-size: 20px;
  font-family: "Gotham HTF Medium 2";
  max-width: 300px;
  width: 100%;
}

.name-prof-block .form-group select {
  margin-bottom: 28px;
}

.emi-block {
  display: flex;
  font-size: 20px;
  align-items: center;
  padding: 20px 0;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  margin: 30px 0 0px;
  text-transform: uppercase;
}

.emi-block h6.head-bold {
  font-size: 20px;
  margin: 0;
  margin-right: 10px;
  color: #f1666b;
}

.take-loans-modal .modal-content {
  background: #f79548;
}

.monthly-emi-value {
  padding-top: 20px;
}

.purchase-inner-menu-block .text-in {
  padding-left: 0;
}

.container:after {
  content: "";
  display: block;
  clear: both;
}

.tab-block .tab-content {
  max-height: 50vh;
  overflow: auto;
}

.no-border {
  border: 0 !important;
}

.box-content {
  background: #fff;
  color: #000;
  font-size: 18px !important;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 20px;
  position: relative;
  margin-bottom: 15px;
}

.box-content.right-box-aboveman {
  padding: 10px;
  margin-top: 20px;
  border-radius: 10px;
}

.box-content.right-box-aboveman:before {
  content: "";
  position: absolute;
  bottom: -20px !important;
  top: auto;
  left: 50%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 20px 20px 0 20px;
  border-color: #fff transparent transparent transparent;
  transform: translate(-50%);
}

.text-label.border-line {
  font-size: 18px;
  border-bottom: 1px solid #fff;
  padding-bottom: 20px;
}

.box-content p {
  font-size: 13px;
}

.box-content:before {
  content: "";
  position: absolute;
  left: -20px;
  top: 50%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 20px 20px 20px 0;
  border-color: transparent #fff transparent transparent;
  transform: translate(0, -50%);
}

.black-trans-bg {
  background: rgba(0, 0, 0, 0.1);
}

.pink-trans-bkg {
  background: rgba(241, 102, 107, 0.1);
}

.left-side-image.image-man {
  float: left;
  height: auto;
  display: inline-table;
  margin-top: 50px;
  padding: 0 20px;
}

p.text-label {
  font-size: 17px;
  font-family: "Gotham HTF Medium 2";
}

.light-purple {
  background: #bc8cbf;
}

.left-side-image {
  padding-top: 20px;
  width: 35%;
}

.red-tooltip {
  position: absolute;
  left: -70%;
  width: 60%;
  background: red;
  padding: 20px 17px;
  color: #fff;
  margin-top: -126px;
  font-size: 18px;
  line-height: 22px;
}

.red-tooltip:after {
  content: "";
  position: absolute;
  right: -20px;
  top: 50%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 20px 0 20px 20px;
  border-color: transparent transparent transparent red;
  transform: translate(0, -50%);
}

.black-text {
  color: #000000;
}

.view-loans-modal .modal-body {
  padding: 10%;
}

.view-loans-modal h5.title-redeem {
  margin: 0;
}

.view-loans-modal .view-wrapper {
  overflow: auto;
  max-height: 45vh;
}

.view-loans-modal .view-wrapper .table-block ul li {
  border-bottom: 1px solid #fff;
  color: #fff;
}

.view-loans-modal .view-wrapper .table-block ul li .li-r-text {
  text-align: center !important;
  width: 25%;
}

.view-loans-modal .view-wrapper .table-block div.left-col {
  padding-right: 17%;
}

.view-loans-modal .view-wrapper .table-block ul li.head-label {
  color: #000000;
}

.view-loans-modal .modal-content {
  background: #f79548;
}

/*** signin style ***/

body.sign-in {
  background: #f1666b;
}

.sign-in .container {
  max-width: 1000px;
}

.logo-idfc img {
  width: 100%;
}

.logo-idfc {
  position: absolute;
  width: 14%;
  left: 5.6%;
  margin-top: 4%;
  z-index: 9;
}

.wrapper-box {
  position: relative;
}

.sign-in-content {
  position: absolute;
  top: 48%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40%;
}

h2.heading-name {
  font-size: 25px;
  margin-bottom: 20px;
  font-family: "gotham-lightgotham-light";
}

label.title-label {
  font-size: 17px;
  font-family: "Gotham HTF Medium 2";
}

.input-field-block.si-input {
  margin-top: 10px;
  padding: 0;
  margin-bottom: 20px;
}

.si-input input {
  border: 1px solid #333 !important;
  border-radius: 30px;
  font-size: 16px;
  padding: 10px 20px;
  margin-bottom: 0;
  margin-right: 10px;
  font-family: "Gotham HTF Medium 2";
}

button.btn.btn-primary.next-btn {
  border-radius: 30px;
  padding: 10px 40px;
  height: 45px;
  margin-left: 0;
}

em.error-text.red {
  color: red;
  font-size: 13px;
  margin-top: 7px;
  margin-bottom: 10px;
  font-family: "Gotham HTF Medium 2";
}

.btn-block > a {
  display: inline-block;
}

.btn-block-div {
  margin-top: 20px;
}

.btn-block2 {
  margin-bottom: 20px;
}

.facebook-btn {
  color: #fff;
  border-radius: 30px;
  background: #3b579d !important;
  padding: 10px 0;
  width: 270px;
}

.facebook-btn:hover {
  background: #3b579d !important;
}

.google-btn {
  color: #fff;
  border-radius: 30px;
  background: #de412c !important;
  padding: 10px 0;
  width: 270px;
}

.google-btn:hover {
  background: #de412c !important;
}

.si-input .input-block {
  margin-right: 10px;
}

.input-field-block.si-input.d-flex.bt-border {
  margin: 30px 0;
}

a.resend-otp {
  float: right;
  font-size: 13px;
  color: #000;
  margin-top: 7px;
  width: 16%;
  font-style: italic;
  text-decoration: underline;
}

.input-field-block.si-input.bt-border {
  margin: 30px 0;
}

.mg-right {
  margin-right: 20px;
}

.button-flex {
  display: flex !important;
}
.button-flex button {
  width: 35% !important;
  margin: 0 !important;
  padding: 5px 14px !important;
  margin-right: 20px !important;
}

.opt-btn-block button.btn.btn-secondary {
  color: #000 !important;
  text-transform: uppercase;
}

.step-block {
  text-align: center;
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 30px 0;
}

.step-block span.step {
  width: 15px;
  height: 15px;
  background: #ebebeb;
  border-radius: 30px;
  text-indent: -99999px;
  display: inline-block;
  margin: 0 19px;
  position: relative;
  z-index: 1;
}

.step-block span.step.current {
  background: #707070;
}

.line-step:after {
  content: "";
  border-top: 1px solid #ccc;
  width: 100%;
  height: 1px;
  position: absolute;
  left: 0;
  top: 7px;
  z-index: 0;
}

.step-block span.step:first-child {
  margin-left: 0;
}

.step-block span.step:last-child {
  margin-right: 0;
}

.otp-main .step-block {
  margin: 0 auto 60px;
  width: 30%;
  max-width: 90px;
}

/******** Profile *********/

.profile-page {
  background: #bc8cbe;
  background: -moz-radial-gradient(
    circle,
    rgba(255, 255, 255, 0.6) 0%,
    rgba(188, 140, 190, 1) 35%
  );
  background: -webkit-radial-gradient(
    circle,
    rgba(255, 255, 255, 0.6) 0%,
    rgba(188, 140, 190, 1) 35%
  );
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 0.6) 0%,
    rgba(188, 140, 190, 1) 35%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#fdc627",GradientType=1);

  background-repeat: no-repeat;
  background-color: rgba(188, 140, 190, 1);
}

.profile-page .container {
  max-width: 1000px;
  height: 100%;
  display: table;
}

.profile-page .container > .row {
  height: 100%;
  margin: 0;
}

.display-table {
  height: 100%;
  display: table;
  padding: 0;
}

.profile-wrapper {
  display: table-cell;
  vertical-align: middle;
  height: 100vh;
  overflow: hidden;
  padding: 0 15px;
}

.profile-main {
  background: #ffffff;
  border-radius: 60px;
  width: 57%;
  min-height: 534px;
  margin: 26px auto 40px;
  padding: 51px;
  position: relative;
}

.profile-main.profile-step {
  padding: 4% 9%;
}

.profile-main label.title-label {
  margin-bottom: 15px;
}

.label-pink {
  color: #f1666b;
  font-size: 17px;
  font-family: "Gotham HTF Medium 2";
  margin: 10px 0px 20px;
}

.couple-img {
  position: absolute;
  bottom: -13px;
  right: -30px;
  width: 35%;
}

.couple-img img {
  width: 100%;
}

.form-content.profile-gender {
  display: flex;
  justify-content: center;
  margin: 10px 0 20px;
  padding: 0;
}

.profile-main .custom-control-label::after {
  display: block !important;
  border-radius: 30px;
  padding: 10px;
  content: "";
  border: 1px solid #000;
  position: absolute;
  top: 3px;
  left: -23px;
  width: 1rem;
  height: 1rem;
}

.profile-main .custom-control-label::before {
  position: absolute;
  top: 6px;
  left: -20px;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #fff solid 3px;
}

.profile-main .custom-control-input:checked ~ .custom-control-label::before {
  display: block;
  background-color: #000;
  border: #fff solid 3px;
  z-index: 1;
}

.profile-main .step-block {
  margin: 20px 0;
}

.form-content.profile-gender label.custom-control-label {
  line-height: 27px;
  font-size: 0;
}

.form-content.profile-gender .custom-control.custom-radio {
  margin: 0 55px !important;
}

.gender-block {
  margin: 0 auto;
  text-align: center;
}

.gender-block img {
}

.profile-main button.next-btn {
  margin: 20px auto 0 !important;
  display: block;
}

.profile-main .button-footer {
  margin-bottom: 0;
  margin-top: 0;
}

.profile-main button.next-btn.left-align {
  margin: 20px 0 0 !important;
}

img.full-img {
  width: 100%;
}

.age-image {
  text-align: center;
  width: 100px;
  margin: 0 auto;
}

.age-image img {
  width: 100%;
}

.name-prof-block .input-field-block {
  margin: 0 auto;
  display: initial;
  text-align: center;
}

.name-prof-block .select-group {
  margin: 0 auto;
}

.profile-main select.form-control {
  border: 1px solid #333;
  font-size: 13px;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 26px;
}

.gender-image {
  display: inline-block;
  text-align: center;
  margin: 0 auto;
}

.gender-image img {
  width: auto;
  height: 180px;
  margin: 0 15px;
}

.form-content.pro-married .custom-control.custom-radio {
  margin: 0 70px !important;
}

.form-content.pro-married
  .custom-control.custom-radio.radio-married
  .custom-control-label {
  margin-left: -25px !important;
}

.children img {
  margin-bottom: -15px;
  width: 100%;
}

.age-image.children {
  width: 180px;
}

.age-slide {
  position: relative;
  left: 0;
  // margin-bottom: 70px;
}

// .age-slide #range {
//     position: absolute;
//     left: 0;
//     margin-top: -43px;
//     background: #ffcc08;
//     border-radius: 5px;
//     padding: 7px 10px;
//     margin-left: -12px;
// }

/*.age-slide #range:after{
	content:"";
	position:absolute;
	bottom: -6px;
	top:auto;
	left:50%;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 6px 6px 0 6px;
	border-color: #ffcc08 transparent transparent transparent;
	transform:translate(-50%,0)
}

.age-slide input {
	width:100%;
}*/

.age-slide .slider {
  background: #f1666b;
  border: 0;
  height: 8px;
  border-radius: 10px;
}

.age-slide .slider.ui-slider-horizontal .ui-slider-handle {
  /*top: 0;
    height: 8px;
    background: #f1666b !important;
	width: 10px;
    margin: 0;*/
  z-index: 99;
  background: #0073ea;
  border: 0;
}

.age-slide:after {
  content: "";
  background: url("/images/ruller-line.png") repeat;
  position: absolute;
  top: 8px;
  left: 0px;
  width: 100%;
  height: 7px;
  background-size: 7px;
}

.age-slide .slider.ui-slider-horizontal .ui-slider-handle .ui-slider-tip {
  opacity: 1 !important;
  visibility: visible;
  background: #ffcc08;
  padding: 0;
  font-size: 17px;
  width: 40px;
  height: 30px;
  line-height: 31px;
  top: -43px;
  border: 0;
}

.age-slide .slider.ui-slider-horizontal .ui-slider-handle .ui-slider-tip:after {
  border-top-color: #ffcc08;
}

.age-slide
  .slider.ui-slider-horizontal
  .ui-slider-handle
  .ui-slider-tip:before {
  display: none;
}

.age-slide .ui-slider-pips .ui-slider-label {
  font-size: 18px;
  color: #f1666b;
  font-family: "Gotham HTF Medium 2";
}

.age-slide .ui-slider-pips .ui-slider-line {
  background: #f1666b !important;
  width: 2px;
  height: 14px;
  position: absolute;
  left: 50%;
  margin-top: -13px;
  z-index: 9;
}

.children-slide-block {
  position: relative;
}

.children-slide-block .text-span {
  position: absolute;
  top: 22px;
  color: #f1666b;
  font-size: 20px;
  font-family: "Gotham HTF Medium 2";
}

.children-slide-block span.text-span.two-child {
  right: 0;
}

.children-slide-block span.text-span.one-child {
  left: 50%;
  transform: translate(-50%);
}

.children-slide-block span.text-span.no-kids {
  width: 100px;
  line-height: 18px;
  margin-top: 5px;
}

.age-slide.children-slide-block:after {
  display: none;
}

/******** Profile Form **********/

.profile-form-block .profile-main {
  padding: 3% 3%;
}

.left-side-pro {
  float: left;
  width: 50%;
}

.right-side-pro-img {
  float: right;
  width: 50%;
  text-align: center;
}

.right-side-pro-img img {
  width: 70%;
}

.left-side-pro h2.heading-name {
  margin-bottom: 10px;
}

.profile-form ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.profile-form ul li {
  float: left;
  width: 48%;
  margin-right: 4%;
}

.profile-form ul li.last {
  margin-right: 0;
}

.input-field-block {
  padding-right: 0;
  margin: 0;
}

.profile-form ul.left-side-gender-input {
  float: left;
  width: 48%;
  margin-top: -55px;
}

.profile-form ul.left-side-gender-input li {
  width: 100%;
}

.profile-form .select-group {
  margin-top: 0;
}

.profile-form .input-field-block label {
  font-size: 18px;
  font-family: "gotham-lightgotham-light";
  margin-bottom: 5px;
  margin-left: 12px;
}

.profile-form ul li.wide-width-input {
  width: 100%;
  margin-right: 0;
  background: #ececec;
  padding: 25px 0 20px;
  border-radius: 20px;
  margin: 10px 0 20px;
  text-align: center;
}

.profile-form ul li.wide-width-input .input-field-block {
  display: inline-block;
  text-align: center;
  width: 70%;
}

li.wide-width-input .input-field-block label {
  width: auto;
  float: left;
  margin: 0;
  margin-top: 7px;
  margin-right: 10px;
}

li.wide-width-input .input-field-block .form-group {
  float: right;
  margin-bottom: 0;
  width: 72%;
}

li.wide-width-input .input-field-block select.form-control {
  margin-bottom: 0;
}

.profile-form-block .profile-content {
  overflow-x: hidden;
  max-height: 400px;
  overflow-y: scroll;
}

.button-sticky {
  position: absolute;
  bottom: 2vh;
  left: 0;
  padding: 0 5% 1%;
  width: 100%;
  background: #fff;
  border-radius: 0 0 30px 30px;
}

.button-sticky button.btn.btn-primary.next-btn {
  margin-top: 10px !important;
}

/*** Desktop resposive **/

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 580px;
  }
}


@media (min-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 100%;
  }

  .header-corner {
    margin-left: 2%;
  }
}

@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 100%;
  }

  .main.d-flex {
    display: block !important;
    background: #ffffff;
    border-radius: 30px;
  }
  .trans.d-flex {
    background: transparent;
  }
}

@media (min-width: 1400px) {
  label.invest-round {
    padding-left: 10%;
  }
}

@media (max-width: 1700px) {
  .profile-car span.number-count b {
    font-size: 25px;
  }
  .in-exp {
    padding: 10px 20px;
  }
  .main-wrapper {
    padding: 20px;
  }
  .tab-block .tab-content {
    max-height: 60vh;
    padding-top: 20px;
  }
  .inv-row {
    margin-bottom: 18px;
  }
  .panel.panel-default {
    margin-bottom: 18px;
  }
  .ic-right-wrap li.ic-list.cash-block {
    padding-top: 14px;
  }
  .ic-right-text .ic-price {
    font-size: 20px;
  }
  .ic-right-wrap li.ic-list {
    height: 130px;
    padding-top: 15px;
  }
  .profile-car {
    padding-top: 15px;
  }
  .ic-right-wrap li.ic-list {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .ic-right-wrap ul {
    margin-top: 45px;
  }
  .ic-right-text .ic-text {
    font-size: 12px;
  }
  .left-nav-menu a {
    padding-top: 29px;
    padding-bottom: 29px;
  }
  .header-corner {
    height: 78px;
  }
}

@media (min-width: 1800px) {
  .tag-box {
    right: 10%;
  }
}

@media (min-width: 1900px) {
}

@media (min-width: 1921px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1900px;
  }

  #header .container {
    padding: 0;
    margin-right: auto;
  }
}

/*** Mobile resposive **/

@media (min-width: 1707px){
  .all-done-section img{
    width: 93% !important;
  }
  .all-done-section{
    min-height: 503px !important;
  }
  .slick-slide img{
    max-width: 717px !important;
    margin-top: 0px;
  }
  .custom-style-btn{
    bottom: -123px !important;
  }
}

@media (min-width: 1821px){
  .custom-style-btn{
    bottom: -112px !important;
  }

  .all-done-section img{
    width: 85% !important;
  }
  .all-done-section{
    min-height: 502px !important;
  }
}


@media (max-width: 1366px) {
  body {
    font-size: 14px;
  }

  .profile-car {
    padding: 15px 10px 0;
  }

  .in-exp {
    padding: 10px 0;
  }

  .ic-right-wrap li.ic-list {
    padding: 20px 1vw;
    height: 130px;
  }

  .ic-right-icon {
    margin-right: 0;
  }

  .tag-box {
    width: 140px;
    padding: 26px;
    font-size: 16px;
    line-height: 21px;
  }
}


@media (max-width: 1200px) {
  .ic-right-text .ic-text {
    font-size: 0.9vw;
  }

  .right-bottom-man img {
    width: 10vw;
  }
}

@media (min-width: 992px) {
  .container .override-center{
    flex: none;
    width: auto;
  }
}

@media (min-height: 781px) {
  .container .override-center{
    margin-top: 8vh;
  }
}

@media (max-width: 991px) {
  .row.mobile-row {
    // display: block;
    margin: 0;
  }

  .couple-img{
    width: 25%;
  }

  .left-main-block {
    padding: 0;
  }

  .for-mobile {
    display: block;
  }

  .home .main-wrapper {
    padding: 0;
    border-radius: 20px 0 0 0 !important;
    height: auto;
  }
  
  .custom-style-height{
    height: 83% !important;
    border-radius: 0 0 30px 30px !important;
  }

  .ic-right-text .ic-text {
    font-size: 16px;
  }

  .for-desktop {
    display: none;
  }

  .text-subtitle {
    display: none;
  }

  .container,
  .container-sm {
    max-width: 100%;
    padding: 0;
  }

  .header-corner {
    margin-left: 10px;
  }

  .logo {
    margin-left: 0;
  }

  .for-mobile .ic-right-wrap ul {
    display: flex;
    align-items: center;
    margin-right: -20px;
  }

  .for-mobile .ic-left-icon {
    display: none;
  }

  .for-mobile .ic-right-wrap {
    border-radius: 0 20px 20px 0;
    width: 70%;
    padding: 5px 10px;
    margin-right: 10px;
  }

  .for-mobile .ic-right-wrap li.ic-list {
    border-radius: 0 20px 20px 0;
    padding: 8px 5vw 5px 8vw;
    display: inline-block;
    height: auto;
    margin-right: -20px;
    position: relative;
    margin-top: 0;
    width: 34%;
  }

  .for-mobile .ic-right-wrap ul {
    margin-top: 0;
  }

  .for-mobile .ic-right-icon {
    margin-right: 0;
    position: absolute;
    top: -13px;
    right: -16px;
    margin: 0;
    background-size: 240px;
    background-position: -106px -251px;
  }

  .ic-list.cash-block {
    z-index: 3;
  }

  .ic-list.card-block {
    z-index: 2;
  }

  .ic-right-wrap li.ic-list.cash-block {
    padding-left: 10px;
    width: 30%;
  }

  .ic-right-text .ic-text {
    line-height: 17px;
  }

  .for-mobile.left-side {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
  }

  .profile-car {
    padding: 20px 10px 0;
    border-radius: 20px 0 0 20px;
    margin-top: 20px;
    width: 27%;
    position: relative;
  }

  .profile-car span.number-count b {
    font-size: 33px;
    display: block;
    line-height: 20px;
  }

  .profile-car .profile-img {
    position: absolute;
    bottom: 0;
    left: 10%;
  }

  .profile-car span.number-count {
    position: absolute;
    right: 9%;
  }

  .left-nav-block {
    max-width: 25vw;
    margin-left: 0;
  }

  .left-nav-menu a {
    padding: 14px 1vw 14px 1vw;
    text-align: center;
    font-size: 15px;
    line-height: 18px;
  }

  span.menu-icon {
    float: none;
    margin: 0 auto 10px;
  }

  .left-nav-menu a:before {
    width: 70%;
  }

  .left-nav-menu {
    border-radius: 0 30px 30px 0;
  }

  .left-nav-menu li:first-child a:hover,
  .left-nav-menu li:first-child a.current {
    border-radius: 0 30px 0 0;
  }

  .left-nav-menu li:last-child a:hover,
  .left-nav-menu li:last-child a.current {
    border-radius: 0 0 30px 0;
  }

  .main {
    float: right !important;
    width: 70%;
    // overflow-y: scroll;
    border-radius: 30px 0 0 0;
    padding: 0;
    background:transparent;
    border-radius: 20px 0 0 0;
    margin-right: 0;
  }

  .custom-style-btn{
    bottom: 30px !important;
  }

  .main-wrapper {
    padding: 20px 0 20px 20px;
    overflow: hidden;
    border-radius: 0;
  }

  .home.main {
    margin: 0;
  }

  .slider {
    height: 510px;
  }

  .slick-list {
    border-radius: 30px 0 0 30px;
    overflow: hidden;
  }

  .slick-dots {
    bottom: -30px !important;
  }

  .slider > div {
    height: 100%;
  }

  .slick-track {
    height: 100%;
  }

  .slick-slide {
    overflow: hidden;
  }

  .slick-slide > div,
  .slick-slide > div > div {
    height: 100%;
  }

  .slick-slide img {
    width: auto !important;
    height: 100%;
    right: -40px;
    position: absolute;
    padding-top: 90px;
  }

  // .slick-slide {
  //   opacity: 0;
  // }

  .banner-content {
    width: 90%;
  }

  .banner-content p {
    font-size: 16px;
    line-height: 18px;
  }

  .banner-content a.btn {
    margin-top: 0;
  }

  .bar1,
  .bar2,
  .bar3 {
    margin: 5px 0;
  }

  .header-corner {
    height: 70px;
  }

  .logo img {
    width: 110px;
  }

  .tab-block .nav.nav-tabs {
    border-radius: 20px 0 0 20px;
  }

  .tab-block .nav.nav-tabs li.make-an-inv a.active {
    border-radius: 20px 0 0 20px;
  }

  .tab-block .nav.nav-tabs li:last-child a.active {
    border-radius: 20px 0 0 20px;
  }

  .tab-block .tab-content {
    padding: 0 0 20px 0;
    height: 81%;
    // overflow-x: scroll;
    position: absolute;
    padding-top: 20px;
    right: 0;
    width: 100%;
    padding-left: 20px;
    // max-height: inherit;
  }

  .inv-row {
    padding: 10px 0 10px 15px;
    border-radius: 20px 0 0 20px;
    margin-bottom: 20px;
  }

  .text-inv {
    font-size: 13px;
    padding-right: 0;
  }

  .inv-row > span.price {
    font-size: 20px;
    padding-left: 10px;
  }

  .panel.panel-default {
    padding: 10px 20px;
    border-radius: 20px 0 0 20px;
    margin-bottom: 20px;
    position: relative;
  }

  .left-nav-menu a:hover:after,
  .left-nav-menu a.current:after {
    right: -10px;
    border-width: 13px 0 13px 13px;
  }

  .panel-heading h4 {
    font-size: 14px;
    width: 40%;
  }

  .icon-s {
    margin-right: 5px;
  }

  .panel-heading .price.inv-price {
    font-size: 20px;
    padding-left: 10px;
  }

  .panel-heading .price {
    font-size: 20px;
    padding-left: 10px;
  }

  .panel-heading[data-toggle="collapse"].collapsed:after {
    background-size: 240px;
    background-position: -158px -4px !important;
  }

  .panel-heading[data-toggle="collapse"]:after {
    right: 0;
    background-size: 240px;
    background-position: -188px -4px !important;
  }

  .table-invesment ul li {
    font-size: 13px;
    padding: 10px 0;
  }

  .table-block ul li {
    font-size: 13px;
    padding: 10px 0;
  }

  .inv-redeem {
    font-size: 11px;
    text-align: center;
  }

  span.redeem-i {
    background-position: 1px -425px;
    background-size: 220px;
    display: block;
    margin: 0 auto;
    margin-bottom: -5px;
  }

  .table-invesment ul li > div.inv-price {
    padding-left: 10px;
  }

  .table-invesment ul li > div.inv-title {
    padding-right: 5px;
  }

  .invest-right img {
    width: 80px;
  }

  label.invest-round {
    font-size: 12px;
  }

  .repay-block label.invest-round {
    padding-left: 5vw;
  }

  .bkg-gray {
    border-radius: 30px 0 0 30px;
    padding: 40px 40px;
  }

  .form-repay {
    padding-right: 0;
  }

  .buy-ins-block .left-side-image {
    width: 34%;
  }

  .buy-ins-block .left-side-image img {
    width: 100%;
  }

  .buy-ins-block .right-side-redeem {
    width: 65%;
  }

  .left-side-image {
    width: 35%;
  }

  .left-side-image img {
    width: 100%;
    height: auto;
  }

  .m-right-side-content {
    width: 65%;
  }

  .idfc-logo-welcome img {
    width: 40%;
  }

  .right-welcom-content {
    margin-top: 30px;
  }

  .welcome-wrapper {
    padding: 0 !important;
    margin: 0 !important;
  }

  .welcome .container > .row {
    margin: 0;
  }

  .welcome .container {
    text-align: center;
    overflow: hidden;
  }

  .welcome-content {
    padding-top: 0;
  }

  .welcome-content > .row {
    padding: 0 20px;
  }

  .welcome .d-flex {
    display: block !important;
  }

  a.lets-start {
    display: inline-block;
    margin-bottom: 20px;
  }

  .already-link {
    display: block;
    margin-bottom: 20px;
  }

  .sign-in-content {
    top: 29%;
    transform: translate(-50%, 0);
    width: 53%;
    height: 57%;
    overflow-x: scroll;
  }

  .welcome.sign-in .container {
    text-align: left;
  }

  .otp-block input {
    width: 100% !important;
    clear: both;
    max-width: 100%;
  }

  .si-input input {
    width: 80% !important;
    clear: both;
    max-width: 100%;
  }

  em.error-text.red {
    color: red;
    font-size: 13px;
    margin-top: 2px;
    margin: 0 auto 9px;
  }

  .input-field-block.si-input {
    margin-bottom: 0;
    margin: 0;
  }

  .logo-idfc {
    position: absolute;
    width: 30%;
    left: 50%;
    transform: translate(-50%);
    margin-top: 7%;
    z-index: 9;
  }

  .profile-main {
    background: #ffffff;
    border-radius: 20px;
    width: 90%;
    min-height: 400px;
    margin: 0 auto 40px;
    padding: 9%;
    height: auto;
    margin-top: 43%;
    position: relative;
  }

  .profile-main .input-field-block {
    width: 100%;
  }

  .profile-main.profile-step {
    padding: 9%;
  }

  .profile-form-block .profile-main.profile-step {
    padding: 3%;
  }

  .profile-form-block .profile-main .input-field-block {
    width: 100%;
    margin-top: 0;
    padding-right: 0;
  }

  .profile-form-block .profile-form .select-group {
    margin-bottom: 5px;
  }

  .profile-form ul.left-side-gender-input {
    margin-top: -12%;
  }

  .profile-main .left-side-pro h2.heading-name {
    font-size: 4vw;
  }

  .profile-main label.title-label,
  .label-pink {
    font-size: 3vw;
  }

  .label-pink {
    width: 70%;
  }

  .profile-form ul li.wide-width-input .input-field-block {
    display: flex;
    text-align: center;
    width: 450px;
    margin: 0 auto;
    padding: 0 4vw;
    justify-content: space-between;
  }

  li.wide-width-input .input-field-block .form-group {
    width: 67%;
  }

  .profile-form ul li.wide-width-input {
    padding: 5vh 0 5vh;
  }

  .name-prof-block .form-group select {
    margin-bottom: 5vh;
  }

  .profile-welcome .si-input input {
    font-size: 2.2vw;
  }
}

@media (max-width: 680px){
  .text-by{
    bottom: -50px !important;
  }
 
}

@media (min-width: 481px) and (max-width: 991px) {
  .profile-main .profile-content.profile-welcome {
    width: 100%;
  }
}

@media (max-width: 640px) {
  .ic-right-text .ic-text {
    line-height: 16px;
    font-size: 2.2vw;
  }

  img.bugs-man-stand {
  width: 40px !important;
  bottom: -20px !important;
  }

  .ic-right-text .ic-price {
    font-size: 4vw;
  }

  .banner-content h1 {
    font-size: 5vw;
  }

  .tab-block .nav.nav-tabs li a {
    line-height: 14px;
    font-size: 12px;
  }

  .profile-car span.number-count b {
    font-size: 7vw;
  }

  .profile-car span.number-count {
    font-size: 4vw;
  }

  .inv-redeem {
    padding-left: 8%;
  }

  .invest-right img {
    width: 70px;
  }

  label.invest-round span.invest-i-right {
    background-size: 270px;
    background-position: -134px -535px;
  }

  .repay-block .panel-heading > div {
    width: 38%;
  }

  label.invest-round span.invest-i-right,
  label.invest-round span.repay-icon {
    left: 1vw;
  }

  .title-repay {
    font-size: 20px;
  }

  .bkg-gray {
    padding: 30px 5vw;
  }

  .profile-form ul li.wide-width-input .input-field-block {
    width: 60vw;
  }

  .name-prof-block .form-group select {
    margin-bottom: 0;
  }
}

@media (max-width: 529px) {
  .insurance-current.height-tabs a {
    height: 48px;
  }
}

@media (max-width: 590px) {
  .left-side-image{
    width: 30%;
  }
  .right-side-redeem{
    width: 70%;
    padding: 0 20px;
  }
  .purchase-tab-content .form-group select{
    font-size: 15px !important;
  }
  .arrow-select.down{
    right: 13px;
    top: 9px;
  }
}

@media (max-width: 480px) {
  .left-nav-menu a {
    font-size: 2vw;
    padding: 10px 1vw 10px 1vw;
  }
  .hand-mobile-slider .slick-slide .banner-content img{
    top: 8px;
  }

  span.menu-icon {
    margin-bottom: 0;
  }

  .banner-content a.btn {
    font-size: 4vw;
  }

  .for-mobile .ic-right-wrap {
    width: 62%;
  }

  .profile-car {
    width: 35%;
  }

  .profile-img img {
    width: 16vw;
  }

  .panel-heading h4 {
    font-size: 12px;
  }

  .panel-heading .price.inv-price {
    font-size: 3.5vw;
  }

  .table-block ul li {
    font-size: 3.5vw;
  }

  .small-text,.table-block div.left-col,.table-block div.mid-col {
    font-size: 12px;
  }

  .panel.panel-default{
    padding: 10px;
  }
  .table-block div.mid-col{
    width: 40%;
  }

  .ic-right-text .ic-text {
    line-height: 12px;
  }

  .left-side-image.float-left img {
    width: 100%;
    height: auto;
  }

  em.error-text {
    font-size: 12px;
    line-height: 15px !important;
    width: 210px !important;
  }

  p.note-text {
    font-size: 12px !important;
    line-height: 15px !important;
  }

  .left-side-image.float-left {
    width: 23%;
  }

  .right-side-redeem {
    padding: 0 4vw;
    width: 80%;
  }

  .purchase-tab-content .form-group select{
    font-size: 10px !important;
  }

  .redeem-radio.form-content.buy-using .custom-control.custom-radio.custom-control-inline.part-of-inv .creditcard{
    margin-left: -23px;
  }

  .right-side-redeem.bt-padding {
    padding: 8vh 4vw;
  }

  h3.subheading-name {
    font-size: 13px;
  }

  h6.subtitle-redeem {
    font-size: 14px;
  }

  label.custom-control-label {
    font-size: 14px;
    line-height: 26px;
  }

  .redeem-radio.form-content.buy-using
    .custom-control.custom-radio.custom-control-inline.part-of-inv {
    margin-bottom: 5px;
  }

  input.input-redeem {
    width: 100%;
  }

  h5.title-redeem {
    margin: 15px 30px 15px 50px;
    font-size: 12px;
  }

  a.close{
    top: -18px;
    right: 30px;
    font-size: 23px;
    width: 30px;
    height: 30px;
  }

  p.text-monthly-emi{
    font-size: 13px !important;
    line-height: 13px !important;

  }

  h3.subheading-name {
    padding-bottom: 3px;
  }

  h6.subtitle-redeem {
    margin-bottom: 6px;
    margin-top: 15px;
    padding-bottom: 6px;
  }

  .custom-control.custom-radio.custom-control-inline {
    margin-bottom: 20px;
  }

  .input-field-block {
    margin-top: 10px;
  }

  input.input-redeem {
    border: 0;
    padding: 5px 5px 3px 10px;
    border-radius: 10px;
    margin-bottom: 8px;
    width: 100%;
    font-size: 13px;
    line-height: 21px;
  }

  .profile-welcome input.input-redeem {
    font-size: 3.3vw;
  }

  .form-content {
    margin-bottom: 10px;
    padding-bottom: 7px;
  }

  .button-footer {
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .btn-primary {
    font-size: 12px;
  }

  .btn-secondary {
    font-size: 12px;
  }

  .modal-body p {
    font-size: 12px;
    padding-top: 5px;
    line-height: 16px;
    margin-bottom: 5px;
  }

  .redeem-confirmation .left-side-image.float-left img {
    width: 100%;
    height: auto;
  }

  .redeem-confirmation .redeem-wrapper {
    display: flex;
    align-items: center;
  }

  .redeem-confirmation {
    padding: 30px 10px;
  }

  .button-footer button.btn.yes-redeem {
    width: 80%;
    max-width: 110px;
  }
  .button-footer button.btn{
    max-width: 80px;
  }

  label.invest-round {
    font-size: 12px;
    line-height: 0;
    padding-left: 7vw;
    width: 22vw;
  }

  .panel-heading .price {
    font-size: 3.5vw;
  }

  .form-group select {
    font-size: 13px;
  }

  .title-repay {
    font-size: 16px;
  }

  .emi-block {
    font-size: 16px;
  }

  .emi-block h6.head-bold {
    font-size: 16px;
  }

  .input-field-block {
    padding-right: 0;
  }

  .repay-your-loans-modal-content .redeem-success h6.subtitle-redeem {
    margin-bottom: 0;
    margin-top: 0;
    padding-bottom: 0;
  }

  .text-in.invest-text {
    padding-left: 10%;
    font-size: 9px;
    margin-left: 3px;
  }

  .inv-redeem button.redeem-btn {
    font-size: 2.5vw;
  }

  .slider {
    height: 400px;
  }

  .right-corner-bottom {
    width: 30px;
    height: 30px;
    bottom: -29px;
  }

  .right-corner-bottom:after {
    width: 30px;
    height: 30px;
    border-radius: 0 30px 0 0;
  }

  .for-mobile.left-side {
    margin-top: 40px;
  }

  .left-nav-menu {
    margin-top: 0;
  }

  .main {
    margin-top: 20px;
  }

  .header-corner {
    height: 60px;
  }

  .left-nav-block {
    margin-top: 20px;
  }

  .box-content p {
    font-size: 2.1vw;
    padding-top: 0;
    line-height: 3vh;
    margin-bottom: 2vh;
  }

  .box-content {
    padding: 3vw;
    margin-bottom: 2vh;
  }

  hr {
    margin: 1vh 0;
  }

  .buy-ins-block.buy-confirm .left-side-image {
    margin-top: 3vh;
  }

  .buy-ins-block.buy-confirm .box-content.right-box-aboveman {
    padding: 5px;
    margin-top: 2vh;
    border-radius: 5px;
    margin-bottom: 8vh;
  }

  .head-title-name {
    margin: 20px 30px 20px 50px;
    font-size: 16px;
  }

  .button-footer button.btn.mobile-wide {
    width: 80%;
  }

  p.red-tooltip {
    font-size: 3vw;
    line-height: 5vh;
    padding: 2vw;
  }

  h2.heading-name {
    font-size: 4vw;
    margin-bottom: 2vw;
  }

  .profile-welcome h2.heading-name {
    font-size: 6vw;
    
  }

  label.title-label {
    font-size: 3vw;
  }

  .sign-in-content input.input-redeem {
    border: 1px solid #333;
    padding: 2px 5px 2px 10px;
    border-radius: 30px;
    margin-bottom: 0;
    width: 100%;
    font-size: 11px;
    line-height: 21px;
    max-width: 90%;
  }

  em.error-text.red {
    margin-top: 2px;
    margin-bottom: 0;
  }

  .otp-block input.input-redeem {
    border: 1px solid #333;
    padding: 5px 5px 3px 10px;
    border-radius: 30px;
    margin-bottom: 0;
    width: 100%;
    font-size: 11px;
    line-height: 21px;
    max-width: 77%;
    margin-right: 0;
  }

  button.btn.btn-primary.next-btn {
    border-radius: 30px;
    padding: 2px 30px;
    height: auto;
  }

  em.error-text.red {
    font-size: 10px;
  }

  .sign-in-content hr {
    margin: 2.5vh 0;
  }

  .facebook-btn,
  .google-btn {
    border-radius: 20px;
    padding: 3px 20px;
    font-size: 2.8vw !important;
    width: 100%;
  }

  .btn-block-div {
    margin-top: 10px;
  }

  .btn-block2 {
    margin-bottom: 8px;
  }

  .already-link {
    margin-bottom: 5px;
    font-size: 9px;
    text-align: center;
  }

  .si-input .input-block {
    margin-bottom: 10px;
  }

  a.resend-otp {
    font-size: 2vw;
  }

  .input-field-block.si-input.bt-border {
    margin: 30px 0;
  }

  .button-flex button {
    width: 45% !important;
    font-size: 2.8vw;
    margin-right: 5px !important;
  }

  .step-block span.step {
    width: 13px;
    height: 13px;
    margin: 0 15px;
  }

  .button-flex button {
    padding: 2px 14px !important;
  }

  .input-field-block.si-input.d-flex.bt-border {
    margin: 10px 0;
  }

  .profile-page .container > .row {
    height: 100%;
    margin: 0;
  }

  .gender-image img {
    height: 120px;
  }

  .form-content.profile-gender .custom-control.custom-radio {
    margin: 0 40px !important;
  }

  .form-content.profile-gender.pro-married .custom-control.custom-radio {
    margin: 0 50px !important;
  }
  .age-slide:after {
    background-size: 1.9%;
  }

  .children-slide-block .text-span {
    top: 17px;
    font-size: 14px;
  }

  .children-slide-block span.text-span.no-kids {
    width: 70px;
  }

  .profile-main select.form-control {
    padding: 1px 30px 1px 10px;
    font-size: 14px;
    height: 34px;
  }

  .profile-form ul li{
    float: none;
    width: 100%;
    margin-right: 0%;
  }

  .profile-main .arrow-select {
    top: 10px;
    right: 8px;
  }

  .profile-form .input-field-block label {
    font-size: 4vw;
  }

  .profile-form-block .profile-content {
    height: 324px;
  }

  .profile-form ul li.wide-width-input {
    padding: 3vh 0 3vh;
  }

  .profile-main select.form-control {
    margin-bottom: 20px;
  }
}

@media (max-width: 360px) {
  .small-text, .table-block div.left-col, .table-block div.mid-col{
    font-size: 11px;
  }
  label.invest-round span.invest-i-right{
    width: 24px !important;
    height: 29px !important;
    left: 1px !important;
    top: -7px !important;
  }
  .table-invesment ul li > div.inv-title,
   .table-invesment ul li > div.inv-price,
   .purchase-inner-menu-block .text-in{font-size: 11px;}

   .light-weight-text a{margin-left: 0px;}
   
   .slick-dots{
    bottom: 8px !important;
  }

  .slider{
    height: 370px;
  }

  .carousel-wrapper{
    min-height: 400px;
  }
   
}


@media (max-width: 325px) {
  .small-text, .table-block div.left-col, .table-block div.mid-col{
    font-size: 9px;
  }
}

@media (max-width: 690px) {
  .profile-form ul li.wide-width-input .input-field-block{
    display: flex;
    flex-flow: column;
    align-items: center;
  }
  li.wide-width-input .input-field-block label{
    margin: 0 auto 10px;
  }
}

@media (max-width: 430px) {
  .tab-block.loans-has-tabs .nav.nav-tabs li a {
    height: 47px;
  }
}

@media (max-width: 400px) {
  .left-nav-menu a {
    padding: 6px 1vw 2px 1vw;
  }
  .left-nav-block,
  .main {
    margin-top: 10px;
  }
}

@media (min-height: 680px) and (max-height: 744px) and (max-width: 768px) {
  .left-nav-menu a {
    font-size: 2vw;
    padding: 6px 1vw 6px 1vw;
  }
  .left-nav-block {
    height: 76vh;
  }
  .left-bar-block .bottom-mobile {
    position: absolute;
    bottom: 0;
  }
}

@media (min-height: 745px) and (max-width: 768px) and (max-width: 768px) {
  .left-nav-menu a {
    font-size: 2vw;
    padding: 10px 1vw 10px 1vw;
  }
  .left-nav-block {
    height: 80vh;
  }

  .left-bar-block .bottom-mobile {
    position: absolute;
    bottom: 0;
  }
}

@media (max-width: 385px) {
  .insurance-current.height-tabs a {
    height: 64px;
  }
}

@media (max-width: 360px) {
  .profile-main select.form-control {
    margin-bottom: 0vh;
  }

}

/* Slider */
.slick-slider {
  position: relative;

  display: block;
  box-sizing: border-box;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;

  display: block;
  overflow: hidden;

  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;

  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before,
.slick-track:after {
  display: table;

  content: "";
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;

  height: 100%;
  min-height: 1px;
}
[dir="rtl"] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;

  height: auto;

  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}

/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;

  position: absolute;
  top: 50%;

  display: block;

  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);

  cursor: pointer;

  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  // opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;

  // opacity: 0.75;
  color: white;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}
[dir="rtl"] .slick-prev {
  right: -25px;
  left: auto;
}
.slick-prev:before {
  content: "←";
}
[dir="rtl"] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}
[dir="rtl"] .slick-next {
  right: auto;
  left: -25px;
}
.slick-next:before {
  content: "→";
}
[dir="rtl"] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -50px;

  display: block;

  width: 100%;
  padding: 0;
  margin: 0;

  list-style: none;

  text-align: center;
}
.slick-dots li {
  position: relative;

  display: inline-block;

  width: 20px;
  height: 20px;
  margin: 0 15px;
  padding: 0;

  cursor: pointer;
}
.slick-dots li button {
  font-size: 0;
  line-height: 0;

  display: block;

  width: 20px;
  height: 20px;
  padding: 5px;

  cursor: pointer;

  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  font-family: "slick";
  font-size: 56px;
  line-height: 20px;

  position: absolute;
  top: 0;
  left: 0;

  width: 20px;
  height: 20px;
  zoom: 0.3;
  -moz-transform: scale(0.3);

  content: "•";
  text-align: center;

  opacity: 1;
  color: #bc8cbf;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  opacity: 1;
  color: #f1666a;
}

/************/

.slider {
  width: 100%;
  margin: 0;
}

.slick-slide > div {
  position: relative;
}

.slick-slide {
  margin: 0px;
}

.slick-slide img {
  width: 100%;
  max-width: 687px;
}


@media (max-width: 1366px){
  .slick-slide img{
    max-width: 626px;
    margin-top: 21px;
  }
  .custom-style-btn{
    bottom: -121px !important;
  }
}


.slick-prev:before,
.slick-next:before {
  color: black;
}

.slick-slide {
  transition: all ease-in-out 0.3s;
  // opacity: 0.2;
}

// .slick-active {
//   // opacity: 0.5;
// }

.slick-current {
  opacity: 1;
}

.block-half img {
  width: 50% !important;
}

.block-half {
  padding: 20px 25px;
  width: 50%;
  float: left;
}
span.buy-title {
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  font-family: "Gotham HTF Bold 2";
  color: #f1666b;
  padding: 20px 10px 10px;
  display: block;
  font-size: 22px;
}
.main-wrapper.tab-block.purchase-inner-menu-block ul.nav.nav-tabs li {
  width: 100%;
}
.main-wrapper.tab-block.purchase-inner-menu-block ul.nav.nav-tabs li a.active {
  text-align: left;
  padding-left: 30px;
}
.main-wrapper.tab-block.purchase-inner-menu-block
  ul.nav.nav-tabs
  li
  a.active::after {
  left: 7%;
}
.main-wrapper.tab-block.purchase-inner-menu-block ul.nav.nav-tabs {
  background: #f1666b;
}
.redeem-radio.form-content.buy-using
  .custom-control.custom-radio.custom-control-inline.part-of-inv {
  margin-bottom: 10px;
}
.red-bg-off {
  background: #d85c60;
}
h3.subheading-name.name-obj {
  border: none;
  margin-bottom: 0;
}
.left-side-image.float-left.buy-bag {
  text-align: center;
}
.left-side-image.float-left.buy-bag img {
  height: auto;
  width: 85%;
}
.wooho .redeem-wrapper.align-middle.buy-bg-block {
  padding: 60px 0;
}
h3.subheading-name.border-none {
  border: none;
}
h2.buy-car-title span {
  float: right;
  color: #000;
  cursor: pointer;
  font-size: 16px;
  padding: 6px;
  width: 80px;
  text-align: right;
  position: relative;
}
h2.buy-car-title {
  font-family: "Gotham HTF Bold 2";
  color: #f1666b;
  padding: 0 10px;
  font-size: 24px;
}
h2.buy-car-title span:after {
  content: "";
  background: #fff;
  width: 15px;
  height: 25px;
  position: absolute;
  left: 8px;
  top: 0px;
}
h2.buy-car-title span:before {
  content: "";
  background: #000;
  width: 15px;
  height: 15px;
  position: absolute;
  left: 0;
  top: 5px;
  transform: rotate(45deg);
}
h2.buy-car-title span,
h2.buy-car-title span a {
  color: #000 !important;
  text-decoration: none;
}
.block-half a {
  text-decoration: none;
  color: #f1666b !important;
  outline: none !important;
}
.purchse-main-block .table-invesment ul li:first-child {
  padding-top: 0;
  border: 0;
}

.purchase-tab-content .left-side-image.float-left img {
  height: 450px;
}

p.note-text {
  color: #000000;
  font-size: 16px;
  line-height: 16px;
  margin-top: 5px;
  font-family: "Gotham HTF Medium 2";
}

.purchase-tab-content .form-group select {
  font-size: 17px;
  line-height: 19px;
}

p.text-monthly-emi {
  color: #000000;
  font-size: 18px;
  line-height: 20px;
  margin-top: 5px;
  text-transform: uppercase;
  font-family: "Gotham HTF Medium 2";
}

@media (max-width: 991px) {
  .tab-block .tab-content {
    right: 0;
    width: 100%;
    padding-left: 20px;
    border-radius: 0 0 30px 30px;
  }
}

@media (max-width: 767px) {
  .purchase-tab-content .left-side-image.float-left img{
    height: auto;
  }
  .block-half {
    padding: 20px;
  }
  span.buy-title {
    font-size: 3vw;
  }
  h2.buy-car-title {
    font-size: 20px;
  }
  span.buy-title {
    padding: 13px 10px 10px;
  }
}
@media (max-width: 440px) {
  h2.buy-car-title {
    font-size: 16px;
    line-height: 26px;
  }
  h2.buy-car-title span,
  h2.buy-car-title span a {
    font-size: 14px;
  }
  h2.buy-car-title span {
    width: 60px;
  }
  h2.buy-car-title span:after {
    width: 10px;
  }
  h2.buy-car-title span {
    padding: 0;
  }
}

#collapseOne {
  display: block !important;
}

#current-inv,
#make-an-inv,
#personal-career,
#personal-family,
#shop-tab,
#shopped-tab,
#repay-loans-tab,
#take-loan-tab {
  text-align: center;
  padding: 10px;
  border-radius: 20px;
  width: 50%;
  display: block;
  color: #ffffff;
  text-transform: uppercase;
  text-decoration: none;
  line-height: 18px;
  position: relative;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 595px){
  #current-inv,
  #make-an-inv,
  #personal-career,
  #personal-family,
  #shop-tab,
  #shopped-tab,
  #repay-loans-tab,
  #take-loan-tab {
    min-height: 75px;
  }
}

#current-inv.active,
#make-an-inv.active,
#personal-career.active,
#personal-family.active,
#shop-tab.active,
#shopped-tab.active,
#repay-loans-tab.active,
#take-loan-tab.active {
  background: #f1666b;
}

#current-inv.active:after,
#make-an-inv.active:after,
#personal-career.active:after,
#personal-family.active:after,
#shop-tab.active:after,
#shopped-tab.active:after,
#repay-loans-tab.active:after,
#take-loan-tab.active:after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 12px 12.5px 0 12.5px;
  border-color: #f1666b transparent transparent transparent;
  position: absolute;
  bottom: -12px;
  left: 20%;
}

.card-header {
  padding: 0px !important;
  background-color: transparent !important;
  border-bottom: none !important;
}

.modal-open {
  z-index: 99999;
  opacity: 0;
  -webkit-transition: opacity 400ms ease-in;
  -moz-transition: opacity 400ms ease-in;
  transition: opacity 400ms ease-in;
  pointer-events: none;
}

.modal-open:target {
  opacity: 1;
  pointer-events: auto;
}

.MuiSlider-markLabel {
  color: #f1666b !important;
  font-size: 0.9rem !important;
  font-family: "Gotham HTF Medium 2" !important;
  // white-space: pre-line !important;
}

.MuiSlider-thumb{
  width: 18px !important;
  height: 18px !important;
  margin-top: -8px !important;
}

/************** Next Stage style **************************************************/

.next-stage-btn {
  display: block;
  width: 100%;
  text-align: center;
  margin-top: 68px;
  background: #f1666b;
  border-radius: 50px;
  line-height: 44px;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 15px;
}

.custom-style-btn{
  position: absolute;
  bottom: -128px;
  left: 10px;
  right: 0;
  max-width: 940px;
}

.next-stage-btn:hover {
  color: #ffffff;
  text-decoration: none;
}

.oops-popup .modal-content {
  background: #4c4c4c;
}

.oops-popup .modal-body {
  padding: 70px 30px;
}

@media (min-width: 576px) {
  .oops-popup .modal-dialog {
    max-width: 500px;
  }
}

.all-done-section {
  position: relative;
  background: #f1666b;
  width: 100%;
  height: auto;
  z-index: 9;
  border-radius: 50px;
  min-height: 460px;
}

.all-done-section img {
  width: 100%;
  min-width: 429px;
  position: absolute;
  right: -40px;
  bottom: 0;
}

@media (min-width: 1060px){
  .alldone-content{
    width: 39% !important;
    background-color: rgba(241, 102, 107,.7) !important;
  }
}


.alldone-content {
  position: absolute;
  top: 23%;
  left: 5%;
  color: #fff;
  width: 26%;
}

.alldone-content h1 {
  font-size: 35px;
  text-transform: uppercase;
  line-height: 40px;
}

.alldone-content p {
  font-size: 13px;
}

img.arrow-white {
  width: 17px;
  margin-left: 10px;
  vertical-align: baseline;
}

.next-stage-btn.inative span {
  opacity: 0.5;
}

.next-stage-btn.ative span {
  opacity: 1;
}

@media (max-width: 980px) {
  .alldone-content h1 {
    font-size: 24px;
    text-transform: uppercase;
    line-height: 24px;
  }

  .alldone-content p {
    font-size: 14px;
  }

  .alldone-content {
    width: 21%;
  }

  .alldone-wrapper img {
    position: relative;
    right: 8vw;
  }

  .next-stage-btn {
    margin-top: 20px;
    border-radius: 30px 0 0 30px;
  }

  .custom-style-btn{
    bottom: 20px !important;
  }

  .all-done-section {
    width: 152%;
    border-radius: 30px;
  }
}

@media (max-width: 768px) {
  .alldone-wrapper img {
    position: relative;
    right: 12vw;
    bottom: -30px;
  }

  .all-done-section {
    width: 159%;
    border-radius: 30px;
  }

  
}

@media (max-width: 400px){
  .custom-style-btn{
    bottom: 0px !important;
  }
}

@media (max-width: 320px){
  .custom-style-btn{
    bottom: -10px !important;
  }
  .all-done-section {
    min-height: 327px !important;
}
.slider {
  height: 300px;
  }
}

@media (max-width: 480px) {
  .all-done-section {
    width: 219%;
    border-radius: 30px;
  }

  .alldone-content {
    width: 16%;
  }

  .alldone-content p {
    font-size: 9px;
  }
}

/*************************************************************/

/********* stage movement ************/

// .stage-movement-page {
//   background: #f1666b;
//   background: -moz-radial-gradient(
//     circle,
//     rgba(255, 255, 255, 0.6) 0%,
//     rgba(241, 102, 107, 1) 35%
//   );
//   background: -webkit-radial-gradient(
//     circle,
//     rgba(255, 255, 255, 0.6) 0%,
//     rgba(241, 102, 107, 1) 35%
//   );
//   background: radial-gradient(
//     circle,
//     rgba(255, 255, 255, 0.6) 0%,
//     rgba(241, 102, 107, 1) 35%
//   );
//   filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#f1666b",GradientType=1);
//   background-repeat: no-repeat;
//   background-color: rgba(241, 102, 107, 1);
//   height: 100vh;
// }

// .right-corner-bottom.dark-pink:after {
//   background: #f1666b;
// }

// .stage-movement-page .section-wrapper {
//   height: calc(100vh - 78px);
//   overflow: hidden;
// }

// .stage-movement-page .section-wrapper .container,
// .stage-movement-page .section-wrapper .container > div,
// .stage-movement-page .main-wrapper {
//   height: 100%;
// }

// .stage-movement-main .main-wrapper {
//   margin-left: 0;
//   padding: 0;
//   background: transparent;
// }

// .stage-movement-main .hand-mobile {
//   float: left;
//   width: 46%;
//   z-index: 9;
//   position: absolute;
//   bottom: 0;
// }

// .stage-movement-main .content-stagemovement {
//   float: left;
//   width: 100%;
//   margin-left: 50px !important;
//   margin-top: 40px;
//   text-align: center;
//   color: #ffffff;
//   margin: 0 auto;
// }

// .hand-mobile img {
//   width: 100%;
// }

// .photo-img {
//   position: absolute;
//   top: 33px;
//   left: 27%;
//   width: 26%;
//   transform: translate(-50%, 0);
//   margin-left: -2px;
// }

// #stage-1 .content-stagemovement .stage-content {
//   max-width: 320px;
//   margin: 10% auto 0;
// }

// .click-btn {
//   font-size: 14px;
//   background: #000000;
//   padding: 6px 30px;
//   border-radius: 40px;
//   line-height: 30px;
//   color: #fff;
//   margin-top: 20px;
//   text-transform: uppercase;
// }

// .content-stagemovement p {
//   font-size: 18px;
// }

// .click-btn:hover {
//   text-decoration: none;
//   color: #ffffff;
// }

// a {
//   outline: 0;
// }

// #stage-2 {
//   height: 100%;
//   z-index: 9;
// }

// div#stage-3 {
//   z-index: 10;
// }

// .start-stage-btn {
//   font-size: 16px;
//   background: #ffcc08;
//   padding: 6px 30px;
//   border-radius: 40px;
//   line-height: 30px;
//   color: #000;
//   margin-top: 20px;
// }

// .start-stage-btn:hover {
//   color: #000;
//   text-decoration: none;
// }

// img.arrow-next2 {
//   width: 17px;
//   margin-left: 10px;
//   vertical-align: baseline;
// }

// .book-block {
//   width: 57%;
//   margin: 0 auto;
//   position: relative;
//   z-index: 9;
// }

// .book-bg {
//   margin: 20px auto 0;
// }

// .book-content {
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
// }

// .book-bg img {
//   width: 100%;
// }

// #stage-3 .stage-content {
//   margin-top: 20px;
// }

// .book-list {
//   padding: 0;
//   margin: 8%;
//   list-style: none;
//   width: 35%;
// }

// .book-list li {
//   display: flex;
//   margin-bottom: 11px;
// }

// .book-list li .face-img {
//   margin-right: 20px;
//   width: 20%;
// }

// .face-img img {
//   width: 100%;
//   max-width: 24px;
//   height: 24px;
// }

// .book-list li p {
//   color: #000;
//   font-size: 11px;
//   font-weight: 300;
//   text-align: left;
//   width: 80%;
// }

// .book-list.float-left {
//   margin-right: 0;
// }

// .btn-next,
// .btn-next2,
// .btn-prev,
// .btn-prev2 {
//   display: none;
// }

// @media (max-width: 991px) {
//   .main.onboard-web-main {
//     float: left !important;
//     width: 100%;
//     padding: 10px;
//     overflow: visible;
//   }


//   .onboard-web-main .howtoplay-slide {
//     float: right;
//     width: 90%;
//     margin-left: 0;
//     margin-top: 30px;
//     margin-right: 10px;
//     height: 60vh;
//   }

//   .howtoplay-slide h1 {
//     font-size: 20px;
//   }

//   .howtoplay-slide p {
//     font-size: 16px;
//   }

//   .howtoplay-slide .slick-list {
//     border-radius: 0;
//   }

//   .howtoplay-slide {
//     border-radius: 20px;
//   }

//   .howtoplay-slide .slick-slide .slide-inner {
//     display: table !important;
//   }

//   .howtoplay-slide .hp-content {
//     position: relative;
//     display: table-cell;
//     vertical-align: middle;
//   }

//   .howtoplay-slide .slick-dots li {
//     margin: 0 6px;
//   }

//   a.btn-button {
//     font-size: 12px;
//     padding: 10px 30px;
//   }

//   .howtoplay-slide:after {
//     left: -22px;
//     top: 40%;
//     border-width: 0 0 61px 90px;
//   }

//   /******/

//   .stage-movement-page .stage-movement-main {
//     height: 100%;
//     overflow: hidden;
//   }

//   .main.stage-movement-main {
//     float: left !important;
//     width: 100%;
//   }

//   .stage-movement-main .main-wrapper {
//     margin-left: 0;
//   }

//   .stage-movement-page{
//     overflow: hidden;
//   }

//   .stage-movement-main .hand-mobile {
//     position: fixed;
//     bottom: 0;
//     left: 50%;
//     width: 310px;
//     transform: translate(-50%);
//     margin-left: 72px;
//   }

//   #stage-1 .content-stagemovement .stage-content {
//     max-width: 280px;
//     margin: 10px auto 0;
//     padding: 0 20px;
//   }

//   .content-stagemovement p {
//     font-size: 14px;
//   }

//   #stage-3 .content-stagemovement p {
//     width: 220px;
//     margin: 20px auto 0;
//     font-size: 16px;
//   }

//   .click-btn {
//     font-size: 11px;
//   }
//   .stage-movement-main .content-stagemovement {
//     margin-left: 0 !important;
//   }

//   .book-block {
//     width: 150%;
//     margin-left: 30px;
//     left: 100%;
//   }

//   .start-stage-btn {
//     display: none;
//   }

//   .btn-next,
//   .btn-next2 {
//     display: block;
//     position: fixed;
//     bottom: 30px;
//     right: 30px;
//     z-index: 9;
//     background: #fff;
//     color: #000;
//     border-radius: 20px;
//     padding: 7px 15px 7px 15px;
//     font-size: 12px;
//     line-height: 21px;
//     text-transform: uppercase;
//   }

//   .btn-next img.arrow-next2,
//   .btn-next2 img.arrow-next2 {
//     margin-left: 20px;
//   }

//   img.arrow-prev {
//     width: 17px;
//     margin-right: 10px;
//     vertical-align: text-top;
//   }

//   .btn-prev,
//   .btn-prev2 {
//     display: block;
//     position: fixed;
//     bottom: 30px;
//     left: 30px;
//     z-index: 9;
//     background: #fff;
//     color: #000;
//     border-radius: 20px;
//     padding: 7px 25px 7px 15px;
//     font-size: 12px;
//     line-height: 21px;
//   }

//   #stage-3 .book-list li p {
//     width: 220px;
//     margin: 0 auto 20px;
//     font-size: 11px;
//     line-height: 16px;
//     margin-bottom: 6px;
//   }

//   .hide-text {
//     opacity: 0;
//   }

//   .show-text {
//     opacity: 1;
//   }

//   #stage-3 {
//     overflow: hidden;
//   }

//   .banner-content {
//     position: relative;
//     top: 20px;
//     // width: 100vw;
//     left: 0 !important;
//   }

//   .hand-mobile-slider .banner-content .hand-mobile {
//     position: absolute;
//   }

//   .hand-mobile-slider .banner-content {
//     position: relative;
//   }

//   .hand-mobile-slider .slick-slide .banner-content img {
//     position: relative;
//     right: 0;
//     height: auto;
//     padding: 0;
//     width: 100% !important;
//   }

//   .hand-mobile-slider.slick-slider {
//     margin-bottom: 0;
//     height: 100vh;
//   }

//   .hand-mobile-slider .slick-list {
//     height: 100%;
//   }

//   .hand-mobile-slider .banner-content {
//     height: 76vh;
//   }

//   .hand-mobile-slider .slick-next {
//     right: 0;
//     z-index: 9;
//   }

//   .hand-mobile-slider .slick-prev {
//     left: 0;
//     z-index: 9;
//   }

//   .hand-mobile-slider .book-block {
//     width: auto;
//     margin-left: 0;
//     left: 0;
//   }

//   .hand-mobile-slider .book-bg-1 {
//     background: url(/images/book-half1.png);
//     background-size: 100% 100%;
//     background-repeat: no-repeat;
//     height: 64vh;
//     left: 12%;
//     top: 30px;
//   }

//   .hand-mobile-slider .book-bg-1 .book-list {
//     padding: 0;
//     margin: 15%;
//     list-style: none;
//     width: 45%;
//   }

//   .hand-mobile-slider .book-bg-2 {
//     background: url(/images/book-half2.png);
//     background-size: 100% 100%;
//     background-repeat: no-repeat;
//     height: 64vh;
//     left: auto;
//     top: 30px;
//     width: 90%;
//   }

//   .hand-mobile-slider .book-bg-2 .book-list {
//     padding: 0;
//     margin: 15% 15% 15% 36%;
//     list-style: none;
//     width: 45%;
//   }

//   .next-stage-slide p {
//     color: #fff;
//     width: 180px;
//     margin: 0 auto;
//     text-align: center;
//     margin-top: 30px;
//   }

//   .hand-mobile-slider .slick-next {
//     background: #fff;
//     color: #000;
//     border-radius: 20px;
//     padding: 7px 15px 7px 15px;
//     font-size: 12px;
//     line-height: 15px;
//     text-transform: uppercase;
//     width: auto;
//     height: auto;
//     right: 20px;
//     bottom: 10px !important;
//     position: fixed;
//     top: auto;
//   }

//   .hand-mobile-slider .slick-next span {
//     background: url(/images/arrow-next2.png) no-repeat 0 0 / 100%;
//     width: 19px;
//     height: 14px;
//     float: right;
//     margin-top: 1px;
//     margin-left: 20px;
//   }
//   .hand-mobile-slider .slick-prev:before,
//   .hand-mobile-slider .slick-next:before {
//     display: none;
//   }

//   .hand-mobile-slider .slick-prev {
//     background: #fff;
//     color: #000;
//     border-radius: 20px;
//     padding: 7px 15px 7px 15px;
//     font-size: 12px;
//     line-height: 15px;
//     text-transform: uppercase;
//     width: auto;
//     height: auto;
//     left: 20px;
//     bottom: 10px !important;
//     position: fixed;
//     top: auto;
//   }

//   .hand-mobile-slider .slick-prev span {
//     background: url(/images/arrow-prev2.png) no-repeat 0 0 / 100%;
//     width: 19px;
//     height: 14px;
//     float: left;
//     margin-top: 1px;
//     margin-right: 20px;
//   }

//   .hand-mobile-slider .slick-prev.slick-disabled {
//     display: none !important;
//   }
// }

// @media (width: 768px) {
//   .stage-movement-main .hand-mobile {
//     position: fixed;
//     bottom: 0;
//     left: 55%;
//     width: 520px;
//   }

//   .book-block {
//     width: 130%;
//     margin-left: 50px;
//   }

//   #stage-3 .book-list li p {
//     font-size: 14px;
//   }

//   .book-list li .face-img {
//     margin-right: 0;
//     width: 14%;
//   }

//   .book-list li {
//     margin-bottom: 30px;
//   }

//   #stage-3 .book-list li p {
//     width: 80%;
//     margin: 0 0 0 30px;
//     font-size: 14px;
//   }
// }

// @media (max-width: 767px) {
//   /*.book-block {
//     width: 100%;}

// 	.stage-movement-main .hand-mobile {
// 		width: 270px;
// 		margin-left: 62px;
// 	}*/
// }

// @media (max-width: 340px) {
//   #stage-3 .content-stagemovement p {
//     margin: 20px auto 0;
//   }

//   .stage-movement-main .hand-mobile {
//     width: 280px;
//     margin-left: 62px;
//   }

//   .book-block {
//     width: 140%;
//   }
// }


/*********New stage Movement************/

.stage-movement-page {
  background: #f1666b;
  background: -moz-radial-gradient(circle, rgba(255,255,255,0.6) 0%, rgba(241,102,107,1) 35%);
  background: -webkit-radial-gradient(circle, rgba(255,255,255,0.6) 0%, rgba(241,102,107,1) 35%);
  background: radial-gradient(circle, rgba(255,255,255,0.6) 0%, rgba(241,102,107,1) 35%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#f1666b",GradientType=1);
  background-repeat: no-repeat;
  background-color: rgba(241, 102, 107, 1);
  height: 100%;
}

.stage-movement-page .right-corner-bottom:after {
  background: #f1666b;
}

.for-deskotp {display:block}

.main-wrapper.trans {
  background:transparent;
}

.main.smm-main {
  margin: 0 auto 0;
  float: none !important;
  background: transparent;
  width: 100%;
}

.smm-main .main-wrapper {
  padding:0
}

.smm-main.smm1-bkg-img {
  margin: 0 auto 0;
  background:url(/images/smm-img1.png) no-repeat top center  / 80%;
  padding-top: 8vw;
}

.smm-main .stand-man {
  float: left;
  width: 25%;
  z-index: 9;
  position: relative;
  margin-left: -25px;
}

.smm-img-left {
  width: 35%;
  height: fit-content;
  position:relative;
}

.smm-img-left img.smm-bubble {
  width: 100%;
  height:100%;
}

.smm-content-1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 70%;
  text-align: center;
}

.smm-content-1 h1 {
  font-size:2vw
}

.smm-content-1 p {
  font-size:1vw
}

.smm-btn {
  font-size: 1vw;
  background: #ffcc08;
  padding: 6px 25px;
  border-radius: 40px;
  color: #000;
  margin: 13px 5px;
  

}

.view-agian-wrap{
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-right: 110px;
}

.view-agian-wrap-mob{
  display: flex;
  justify-content: space-around;
}

.view-agian{
  background-color: transparent;
  font-size: 16px;
  color: #fff;
  outline: none;
  border: none;
}

.view-agian:focus {
  outline: none;
}

.view-agian img{width: 17px; margin-right: 5px;}

.smm-btn:hover {
  color: #000;
  text-decoration:none
}

.al-left {
  text-align:left !important
}

.smm2 .smm-img-left {
  width: 40%;}

.smm-main.smm2 .main-wrapper {
  padding:20px 0 20px 20px
}

.smm-content-2 p {
  font-size: 1vw;
  line-height: 1.2;
}

.smm-content-2 {
  position: absolute;
  top: 54%;
  left: 43%;
  transform: translate(-50%,-50%);
  width: 52%;
  text-align: left;
}

.smm-calc-diary .right-calc-img2 {
  float: left;
  width: 40%;
  z-index: 9;
  position: relative;
  margin-left: 30px;
  margin-top: 20px;
  text-align: left;
  display: flex;
  flex-flow: column;
  /* justify-content: flex-start; */
  align-items: flex-start;
}

img.pro-icon-img {
  position: absolute;
  width: 40px !important;
  top: 27%;
  right: 16%;
}

.right-calc-img2 img.calc-img {
  width: 60%;
  display: block;
  margin-bottom: 40px;
}

.right-calc-img2 .smm-btn {
  width: auto;
  display: inline-block; margin-left: 0;}

img.arrow-next2 {
  vertical-align: middle;
}


/**********/

.smm-diary-block {
  width: 40%;
  height: fit-content;
  position: relative;
  padding-top: 10px;
  margin-right: 40px;
}

.smm-diary-block img {
  width:100%
}

.align-center {text-align:center}

.smm-sc {
  position: absolute;
  top: 50%;
  left: 48%;
  transform: translate(-50%,-50%);
  width: 70%;
}

h1.headingname {
  font-size: 1vw;
  color:#f1666b;
}

.smm-list-stage .panel.panel-default {
  margin-bottom: 8px;
}

.smm-list-stage .panel.panel-default {
  padding: 0;
  color:#000000;
  background: transparent;
}

.smm-list-stage .panel-heading > div {
  color:#000000;
  padding:6px 12px 3px;
  font-size: 1vw;
}

.smm-list-stage .panel-heading .left-col {
  width: 33%;
  background:#ffa506;
  border-radius: 20px 0 0 20px;
  text-align: right;
  font-family: "gotham-mediumgotham-medium";
  /*font-size: 0.95vw;*/
}

.smm-list-stage .panel-heading .mid-col {
  width: 33%;
  background:#f9ba4b;
  position:relative;
}

.smm-list-stage .panel-heading .mid-col.right-arrow:after{
  background:url(/images/right-arrow-orange.png) no-repeat 0 0 / 100%;
  content:"";
  position:absolute;
  right:-10px;
  top:0;
  width: 10px;
  height: 100%;
}

/*.smm-list-stage .panel-heading .mid-col.right-arrow:after{
  content:"";
  position:absolute;
  right:-10px;
  top:0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px 0 15px 15px;
  border-color: transparent transparent transparent #f9ba4b;
}*/

.smm-list-stage .panel-heading .right-col {
  width: 33%;
  background:#ffcc08;
  border-radius: 0 20px 20px 0;
  text-align: left;
  padding-right: 0;
}

.smm-list-stage {
  // margin-top: 20px;
}

.smm-list-stage .thead {background:transparent;}

.smm-list-stage .thead .panel-heading > div {
  background:transparent;
  font-size: .55vw;
  padding: 0 12px;
}


img.w100 {
  width:100%
  }

.sticky-note-block {position:relative;}
.stickynote-content {position:absolute; top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 60%;
  text-align: left; font-size: 0.9vw;}


h1.headingname.color-blue {
  color:#3c93c5;

  }

.smm-list-stage .panel.total-heading-block .panel-heading{
  background:#fcdd5f;
  border-radius:30px;
  cursor: default;
  }

.smm-list-stage .total-heading-block .panel-heading > div{
  background:transparent;
  text-align: left;
  }

.sm-rt-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 0 5px 7px;
  border-color: transparent transparent transparent #000000;
  float: right;
  margin-top: 3px;
  margin-right: -9px;
  }

.smm-list-stage .smm-accordion .panel .panel-heading.accordion-toggle{
  background:#d6b0d0;
  border-radius:30px;

  }

.smm-list-stage .smm-accordion .panel .panel-heading.accordion-toggle > div{
  text-transform:uppercase;
  }

.smm-list-stage .smm-accordion .panel .panel-heading > div{
  background:transparent;
  text-align: left;
  }

.smm-list-stage .smm-accordion .panel-heading[data-toggle="collapse"].collapsed:after {
  background-position: 0 -29px !important ;
  }

.smm-list-stage .smm-accordion .panel-heading[data-toggle="collapse"]:after {
  content: "";
  position: absolute;
  color: #b0c5d8;
  font-size: 18px;
  line-height: 22px;
  right: 5px;
  top: 3px;
  width: 15px;
  height: 17px;
  background: url(/images/accordion-updown.png) no-repeat 0 0 / 100% !important;
}

.table-smm ul li {
    font-size: 12px;
   display:flex; 
   border-bottom: 1px solid #ffcc08; 
   position: relative; 
  //  padding-bottom: 18px;
  }

.table-smm ul li > div {
  width:33%;
  padding: 6px 12px 3px;
  text-align: left;
  }

// .accordion-wrapper .panel-body {
//   margin-top: 20px;
// }

.accordion-wrapper.smm-accordion .panel-body {
  // margin-top: 10px;
  margin-left: 10px;
  // min-height: 40px;
  max-height: 160px;
  overflow-y: auto;
  overflow-x: hidden;
}


.smm-accordion .panel-collapse .panel-body {
  margin-top: 10px;
  min-height: 60px;
  max-height: 160px;
  overflow-y: auto;
}

.smm-sc.sc-4 {
  position: absolute;
  top: 11%;
  left: 48%;
  transform: translate(-50%,0);
  width: 73%;
  height: 82%;
    overflow: hidden;
  }

.sc-4 .smm-list-stage .panel-heading .mid-col {
  width:22%;
  }

.sc-4 .smm-list-stage .panel-heading .right-col {
  width:30%
  }

.sc-4 .smm-list-stage .panel-heading .left-col {
  width:48%; text-align: left;
  }

.smm-list-stage .panel-heading > div {
  color: #000000;
  padding: 6px 10px 3px 12px;
  font-size: .8vw; white-space: nowrap;}

.smm-list-stage .thead .panel-heading > div {
  white-space: break-spaces;
  text-align: left;
}
.redeemed{
  color: #7d7c7d;
  font-size: 9px;
  position: absolute;
  right: -7px;
  bottom: 2px;
  width: auto !important;
  font-style: italic;
}
.sc-4 .table-smm ul li > div {
  padding: 10px 10px 3px 4px;
  }

.sc-4 .table-smm ul li > div.left-col {
  width: 48%; 
  }

.sc-4 .table-smm ul li > div.mid-col {
  width: 30%; 
  }

.sc-4 .table-smm ul li > div.right-col {
  width: 30%; 
  }

.table-smm ul li:last-child {
  border:0
  }


/* width */
.scrollbar::-webkit-scrollbar {
  width: 4px;
  }

/* Track */
.scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0px grey; 
  border-radius: 20px;
  background:#dfdfdf;
  }

/* Handle */
.scrollbar::-webkit-scrollbar-thumb {
  background: #f1666b; 
  border-radius: 10px;
  }

/* Handle on hover */
.scrollbar::-webkit-scrollbar-thumb:hover {
  background: #dc161d; 
  }

.smm-sc.sc-5 {
  top: 15%;
  left: 50%;
  transform: translate(-50%,0);
  width: 80%;
  height: 82%;
  // overflow: hidden;
  }

.fbs-table-list.table-smm ul li > div.left-col {
  width: 66%;
  }

.fbs-table-list.table-smm ul li > div.right-col {
  width: 34%;
  }

.fbs-table-list.table-smm ul li > div {
  font-size: 0.8vw;
  padding: 3px 9px;
  }

.fbs-table-list.table-smm ul li {
  border-bottom:1px solid #bababa;
  }

.fbs-table-list.table-smm ul li.nonborder {
  border:0
  }

.fbs-table-list.table-smm ul li.fbs-heading {
  border:0
  }

.fbs-table-list.table-smm ul li.start-balance {
  margin-bottom:10px
  }

.fbs-table-list.table-smm ul li.fbs-heading .left-col {
  color:#f1666b;
  font-size: 1vw;
}


span.face-smile {
    position: absolute;
  width: 20px;
  height: 20px;
  background: url(/images/smm5-icons.png) no-repeat 0 0 / 220%;
  margin-left: 8px;
  margin-top: -3px;
  }

span.face-sad {
  position: absolute;
  width: 20px;
  height: 20px;
  background: url(/images/smm5-icons.png) no-repeat 0 -22px / 220%;
  margin-left: 8px;
  margin-top: -3px;
  }

.new-balance-block {
  padding: 3px 15px;
  border-radius: 10px;
  background: #ffcc08;
  border: 1px solid #edc276;
  margin-top:10px
  }


.new-balance-block .left-col {
  width: 70%;
  text-align: left;
  white-space: nowrap;
  }

.new-balance-block .right-col {
  width: 30%;
  text-align: left;
  white-space: nowrap;
  }

.new-balance-block.nb-red {
  background: #ff0030;
  color:#ffffff;
  }

.note-ohno {
  margin-top: 10px;
  }

.no-img {
  width: 12%;
  }

img.icon-ohno {
  width:100%;
  }

.note-ohno p {
  width:80%;
  font-size: 0.55vw;
  text-align: left;
  margin-left: 10px;
  }


@media (max-width:1024px){
.smm-bubble-left {
  width: 43%;}
  .paylater-tooltip {
    left: 30%;
    top: -48%;
}
  }

@media (min-width:769px){
.smm-btn.for-mobile{display:none}
  }




@media (max-width:768px){
.smm-main.smm1-bkg-img{    float: none !important;
  width: 100%; background: url(/images/smm-img1.png) no-repeat 35% top / 100%;
  padding-top: 8vw;}

  #stage-1{margin-top: 60px;}

.smm-main .stand-man {
  width: 44%;
  margin-left: -25px;
  margin-right: -35px;
  }

.smm-img-left {
  width: 65%;
  }

.smm-content-1 p {
  font-size: 2.7vw;
  margin-bottom: 1vh;
  position: relative;
  right: 15px;
  }

.smm-content-1 h1 {
  font-size: 6vw;
  }

.start-stage-btn {
  display: block;
  }

.smm-btn {
  font-size: 3vw; 
  width: 100%;
  display: block;
  }

.smm1 .smm-btn {
  margin: 10px 0 10px 0;
  }

.for-deskotp {display:none}

.smm2 .smm-img-left {
  width: 80%;
  margin: 0;
  }

.smm-bubble{
  width: 97% !important;
}

.goitund.smm-btn {
  width: auto;
  display: inline-block;
}

.smm-main.smm2 {
  width: 100%;
  text-align: center;
  }

.smm-calc-diary .right-calc-img2{
  display: none;
}

.smm2 .smm-content-2 p {
  font-size: 3vw;
  line-height: 1.2;
  }

.smm-diary-block {
  width: 94%;
  margin-right: 0px;
}

.smm-list-stage .panel-heading > div {
  font-size: 2.7vw;
  }

.smm-list-stage .panel-heading .left-col {
  font-size: 2.7vw;
  }

h1.headingname {
  font-size: 4vw;
  }

.stickynote-content {
  font-size: 3.5vw;}

.smm-btn.smm-center {
  display: inline-block;
  width: auto;
  }

.smm-list-stage .thead .panel-heading > div {
  font-size: 2.3vw;
}

.fbs-table-list.table-smm ul li.fbs-heading .left-col {
  font-size: 3.5vw;
}

.fbs-table-list.table-smm ul li > div {
  font-size: 3vw;
}

.new-balance-block > div {
  font-size: 4vw;}

.note-ohno p {
  width:80%;
  font-size: 2vw;
  text-align: left;
  margin-left: 10px;
  }

.no-img {
  width:17%
}
  }

@media (max-width:340px){
.smm-btn {
  font-size: 10px;
  background: #ffcc08;
  padding: 0 19px;
  border-radius: 40px;
  line-height: 30px;
  color: #000;
  margin-top: 12px;
  }

.smm-sc.sc-5 {
  top: 13%;}
}

/*************************************************/

/****************  Game Win Lose **********************/

.game-wl .container {
  max-width: 750px;
  height: 100%;
  display: table;
}

h2.heading-big {
  color: #f1666b;
  font-size: 40px;
  font-weight: 800;
}

.share-block {
  margin: 30px 0 0;
}

.game-wrapper {
  height: 100vh;
  display: table;
}

.game-content {
  display: table-cell;
  vertical-align: middle;
}

.share-block label {
  margin: 0;
  font-size: 13px;
  margin-right: 14px;
}

.share-block a {
  background: url(/images/social-icons.png) no-repeat 0 0;
  width: 30px;
  height: 30px;
  text-indent: -9999px;
  float: left;
  margin: 0 6px;
}

.share-block a.mail {
  background-position: 0 0;
}

.share-block a.fb {
  background-position: -48px 0;
}

.share-block a.in {
  background-position: -96px 0;
}

.share-block a.tw {
  background-position: -144px 0;
  margin-right: 0;
}

a.btn-playagain {
  color: #fff;
  background: #f1666b;
  border-radius: 40px;
  padding-left: 20px;
  padding-right: 20px;
  line-height: 20px;
  text-transform: uppercase;
}

a.btn-playagain:hover {
  color: #ffffff;
}

.right-content p a {
  color: #000000;
  text-decoration: underline;
}

.game-content {
  display: table-cell;
  vertical-align: middle;
}

.text-bottom {
  padding-top: 40px;
}

@media (max-width: 991px) {
  .game-content > .row {
    padding: 0 20px;
    flex-direction: column-reverse;
  }

  .game-content .idfc-logo-welcome {
    padding: 20px 30px 0;
  }

  .game-content {
    padding-top: 0;
  }

  .game-wrapper {
    padding: 0 !important;
    margin: 0 !important;
  }

  .game-wrapper {
    padding-top: 0;
  }

  .game-content p {
    margin-bottom: 8px;
  }

  .play-again-btn {
    float: left;
    margin-top: 10px;
  }

  .share-block {
    margin: 10px 0 0 0;
    display: block !important;
    float: right;
  }

  .share-block a {
    margin: 0 11px 0 0;
  }

  .game-wl .container {
    overflow: hidden;
  }

  a.btn-playagain {
    font-size: 12px;
    border-radius: 5px;
    line-height: 11px;
    margin-top: 24px;
  }

  .share-block label {
    margin-bottom: 3px;
    text-align: left;
    display: block;
  }

  .game-wl .right-content {
    margin-top: 20px;
    text-align: left;
    padding: 0 30px;
  }

  .play-again-btn.for-lost {
    float: none;
  }

  .play-again-btn.for-lost a.btn-playagain {
    margin-top: 10px;
  }
}

@media (max-width: 768px) {
  h2.heading-big {
    font-size: 30px;
    margin-bottom: 5px;
    text-align: left;
  }

  .game-wl.game-won h2.heading-big {
    text-align: center;
  }

  .game-wl .right-content {
    text-align: left;
  }

  .game-wl.game-won .right-content {
    text-align: center;
  }
}

@media (max-width: 340px) {
  .game-content p {
    margin-bottom: 8px;
    font-size: 11px;
  }
}

.btn.giveup {
  background: #f1666b !important;
}

/*************************************************************/

/****** Bugsoverlay popup ***********/

.bugsoverlay-popup .modal-content {
  background: #ffffff;
  border-color: #fdc627;
  padding: 10%;
}

.bugsoverlay-popup:before {
  content: "";
  display: block;
  position: fixed;
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
}

.bugsoverlay-popup .modal-body {
  border-radius: 0;
}

h2.title-heading-name {
  color: #000;
  font-size: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #fff;
  padding-bottom: 7px;
  font-family: "gotham-mediumgotham-medium";
  text-transform: uppercase;
}

img.bugs-man-stand {
  position: absolute;
  right: -70px;
  bottom: -70px;
  width: 150px;
}

@media (min-width: 576px) {
  .bugsoverlay-popup .modal-dialog {
    max-width: 840px;
  }
}

@media (max-width: 991px) {
  .bugsoverlay-popup .modal-body {
    padding-bottom: 12vh;
  }

  .custom-style-valign{
    padding-bottom: 0 !important;
  }

  img.bugs-man-stand {
    position: absolute;
    right: 0;
    bottom: -60px;
    width: 140px;
  }

  .bugsoverlay-popup .bugs-content {
    padding-right: 13%;
  }

  .bugsoverlay-popup .bugs-content .button-footer {
    padding-right: 33%;
  }

  .bugsoverlay-popup .modal-dialog {
    margin: 0 20px;
  }

  .bugsoverlay-popup h2.title-heading-name {
    font-size: 15px;
    margin-bottom: 10px;
    padding-bottom: 0;
  }
}

@media (max-width: 767px) {
  img.bugs-man-stand {
    max-width: 18vw;
    bottom: -40px;
  }
}

@media (max-width: 340px) {
  .bugsoverlay-popup .modal-body {
    padding-bottom: 6vh;
  }
}

/********************************************************/

/********************   INVESTMENT   ************************/
.left-man-blue {
  /*background: #a97eac;*/
  text-align: center;
  padding: 10px 20px 0;
}
.left-side-image.float-left.left-man-blue img {
  height: auto !important;
  width: 90%;
}
.left-side-image.float-left.left-man-blue {
  margin-top: 54px;
  height: 380px;
  width: 180px;
}
button.btn.btn-primary.view-history {
  font-size: 11px;
  text-transform: uppercase;
  width: 80px;
  text-align: left;
  padding: 3px 15px 3px;
  line-height: 16px;
  position: absolute;
  right: 20px;
  bottom: 10px;
}
.confirmation-tooltip {
  background: #fff;
  position: relative;
  padding: 15px;
  margin: 15px;
  border-radius: 10px;
  max-width: 70%;
  font-size: 15px;
  margin-bottom: 20px;
}
.box-content.ppf::before {
  top: 35%;
  left: -18px;
}
.confirmation-tooltip:before {
  content: "";
  position: absolute;
  left: 40%;
  bottom: -20px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 20px 20px 20px 0;
  border-color: transparent #fff transparent transparent;
  transform: translate(0, -50%);
  transform: rotate(-90deg);
}
.right-side-redeem.float-right.equity-large-text {
  width: 80%;
}
.equity-large-text select#exampleSelect1 {
  font-size: 15px;
}

@media (max-width: 480px) {
  .confirmation-tooltip {
    padding: 5px;
    max-width: 100%;
    font-size: 10px;
    margin: 6px;
    line-height: 15px;
  }
  button.btn.btn-primary.view-history {
    font-size: 9px !important;
    padding: 6px;
    width: 60px;
    line-height: 12px;
    right: 8px;
    bottom: 8px;
  }
  .left-side-image.float-left.left-man-blue {
    margin-top: 35px;
  }

  .left-side-image.float-left.left-man-blue img {
    height: 90% !important;
    width: 21vw;
  }
  .left-side-image.float-left.left-man-blue {
    margin-top: 35px;
    margin-right: 3%;
    width: 35%;
    height: auto !important;
    max-height: 210px !important;
  }
}
@media (max-width: 375px) {
  button.btn.btn-primary.view-history {
    font-size: 8px !important;
    padding: 6px;
    width: 50px;
    line-height: 11px;
    right: 8px;
    bottom: 8px;
  }
  .custom-style-height {
    height: 75% !important;
}
.left-nav-block{
  height: 70vh;
}
.slick-slide img{
  height: 76%;
}
.next-stage-btn{
  margin-top: 12px;
}
.paylater-tooltip {
  left: 34% !important;
  width: 25px;
  top: -60%;
}
}

.invest-now-better-part2 {
  padding: 30px;
}

h4.title-heading-name {
  color: #ffffff;
  font-size: 22px;
  margin: 20px 20px 40px 30px;
}

.graph img {
  width: 100%;
}

.img-width img {
  width: 100%;
}

.invest-now-better-part2 .button-footer button.btn {
  max-width: 120px;
  width: 50%;
}

.margin-b {
  margin-bottom: 0;
}

@media (max-width: 991px) {
  .invest-now-better-part2 {
    padding: 20px;
  }
}

@media (max-width: 767px) {
  h4.title-heading-name {
    font-size: 16px;
    margin: 20px 20px 30px 20px;
  }
}

@media (max-width: 480px) {
  h4.title-heading-name {
    font-size: 14px;
  }
}

.with-riskometer {
  margin-top: 30px;
}
.left-side-image.float-left.left-man-blue.with-meter {
  margin-top: 0;
}

.with-riskometer .text-riskometer {
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 17px;
  font-family: "gotham-boldgotham-bold";
}

.bottom-text-popup {
  text-align: center;
  padding: 0 40px 10px;
  font-size: 14px;
}

.bottom-text-popup a.text-line {
  color: #212529;
}

.text-line {
  text-decoration: underline;
}

label.invest-round span.view-icon {
  width: 30px;
  height: 38px;
  background: url(/images/split-img.png) no-repeat -210px -707px;
  display: inline-block;
  position: absolute;
  left: 10px;
  top: -11px;
}

.view-btn-right .text-in {
  padding-left: 31%;
}

.withriskmeter1 .box-content p {
  font-size: 16px;
}

p.mar0 {
  margin-bottom: 0;
}

.withriskmeter1 .box-content:before {
  top: 30%;
}

label.invest-round span.invest-i-right {
  width: 30px;
  height: 38px;
  background: url(/images/split-img.png) no-repeat -157px -635px;
  display: inline-block;
  position: absolute;
  left: 10px;
  top: -11px;
}

#makeaninvestment label.invest-round span.invest-i-right {
  width: 32px;
    height: 38px;
  background: url(/images/split-img.png) no-repeat -157px -635px;
  background: url(/images/split-img.png) no-repeat -156px -708px;
    display: inline-block;
  position: absolute;
    left: 7px;
    top: -11px;
}

@media (max-width: 640px) {
  #makeaninvestment label.invest-round span.invest-i-right {
    background: url(/images/split-img-n.png) no-repeat;
    background-position: 2px 11px;
    background-size: 25px 18px;
  }
}

/********************   INVESTMENT   ************************/

/***********************onboard-web.css********/

.light-weight-text {
  font-family: "gotham-mediumgotham-medium";
}

.howtoplay-slide {
  background: #ffffff;
  padding: 7%;
  border-radius: 50px;
}

.howtoplay-slide .hp-content {
  position: relative;
}

a.btn-button {
  font-size: 16px;
  background: #f1666b;
  padding: 6px 30px;
  border-radius: 40px;
  line-height: 30px;
  color: #fff;
  margin-top: 20px;
}

a.btn-button:hover {
  text-decoration: none;
}

a.btn-button:focus {
  outline: 0;
}


.stand-man img {
  width: 100%;
}

.onboard-web-main .howtoplay-slide {
  float: left;
  width: 58%;
  margin-left: -30px;
  margin-top: 40px;
}

.howtoplay-slide h1 {
  font-size: 24px;
  text-transform: uppercase;
  color: #f1666b;
}

.howtoplay-slide .slick-dots {
  left: 0;
}

.howtoplay-slide p {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 18px;
}

.onboard-web-main .main-wrapper {
  margin-left: 60px;
}

.howtoplay-slide:after {
  content: "";
  position: absolute;
  left: -32px;
  top: 15%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 50px 70px;
  border-color: transparent transparent #ffffff transparent;
  transform: translate(0, -50%);
  z-index: -1;
}

/********* stage movement ************/

body.stage-movement-page {
  background: #f1666b;
  background: -moz-radial-gradient(
    circle,
    rgba(255, 255, 255, 0.6) 0%,
    rgba(241, 102, 107, 1) 35%
  );
  background: -webkit-radial-gradient(
    circle,
    rgba(255, 255, 255, 0.6) 0%,
    rgba(241, 102, 107, 1) 35%
  );
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 0.6) 0%,
    rgba(241, 102, 107, 1) 35%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#f1666b",GradientType=1);
  background-repeat: no-repeat;
  background-color: rgba(241, 102, 107, 1);
  height: 100%;
}

.right-corner-bottom.dark-pink:after {
  background: #f1666b;
}

.stage-movement-page .section-wrapper {
  height: calc(100vh - 78px);
  overflow: hidden;
}

.stage-movement-page .section-wrapper .container,
.stage-movement-page .section-wrapper .container > div,
.stage-movement-page .main-wrapper {
  height: 100%;
}

.stage-movement-main .main-wrapper {
  margin-left: 0;
  padding: 0;
  background: transparent;
}

.stage-movement-main .hand-mobile {
  float: left;
  width: 46%;
  z-index: 9;
  position: absolute;
  bottom: 0;
}

.stage-movement-main .content-stagemovement {
  float: left;
  width: 100%;
  margin-left: 50px !important;
  margin-top: 40px;
  text-align: center;
  color: #ffffff;
  margin: 0 auto;
}

.hand-mobile img {
  width: 100%;
}

.photo-img {
  position: absolute;
  top: 33px;
  left: 27%;
  width: 26%;
  transform: translate(-50%, 0);
  margin-left: -2px;
}

#stage-1 .content-stagemovement .stage-content {
  max-width: 320px;
  margin: 10% auto 0;
}

.click-btn {
  font-size: 14px;
  background: #000000;
  padding: 6px 30px;
  border-radius: 40px;
  line-height: 30px;
  color: #fff;
  margin-top: 20px;
  text-transform: uppercase;
}

.content-stagemovement p {
  font-size: 18px;
}

.click-btn:hover {
  text-decoration: none;
  color: #ffffff;
}

a {
  outline: 0;
}

#stage-2 {
  height: 100%;
  z-index: 9;
}

#stage-4 .style-none li{
  padding-bottom: 10px !important;
  display: flex;
  align-items: center;
}

div#stage-1 {
  z-index: 7;
}

div#stage-3 {
  z-index: 8;
}

.start-stage-btn {
  font-size: 16px;
  background: #ffcc08;
  padding: 6px 30px;
  border-radius: 40px;
  line-height: 30px;
  color: #000;
  margin-top: 20px;
}

.start-stage-btn:hover {
  color: #000;
  text-decoration: none;
}

img.arrow-next2 {
  width: 17px;
  margin-left: 10px;
  vertical-align: baseline;
}

.book-block {
  width: 57%;
  margin: 0 auto;
  position: relative;
  z-index: 9;
}

.book-bg {
  margin: 20px auto 0;
}

.book-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.book-bg img {
  width: 100%;
}

#stage-3 .stage-content {
  margin-top: 20px;
}

.book-list {
  padding: 0;
  margin: 8%;
  list-style: none;
  width: 35%;
}

.book-list li {
  display: flex;
  margin-bottom: 11px;
}

.book-list li .face-img {
  margin-right: 20px;
  width: 20%;
}

.face-img img {
  width: 100%;
}

.book-list li p {
  color: #000;
  font-size: 11px;
  font-weight: 300;
  text-align: left;
  width: 80%;
}

.book-list.float-left {
  margin-right: 0;
}

.btn-next,
.btn-next2,
.btn-prev,
.btn-prev2 {
  display: none;
}

/******** Onboard web2*************************************************/

.onboard-web2 .box-slide {
  position: relative;
  float: left;
  width: 100%;
  max-width: 651px;
  height: auto;
  margin-left: 100px;
  margin-top: 0;
  padding: 40px;
  padding-bottom: 130px;
}

.onboard-web-main.onboard-web2 .stand-man {
  // float: left;
  width: 150px;
  z-index: 9;
  position: absolute;
  // top: 28%;
  left: 0;
  bottom: 0;
}

.text-toknow {
  position: absolute;
  left: 40px;;
  bottom: 40px;
  clear: both;
  float: right;
  width: 80%;
  margin-top: -50px;
  font-size: 12px;
  font-weight: 400;
  font-family: "gotham-mediumgotham-medium";
}

.text-by {
  // clear: both;
  // float: right;
  position: absolute;
  bottom: -20px;
  margin: auto;
  text-align: center;
  max-width: 700px;
  width: 90%;
  margin-top: 20px;
  font-size: 12px;
  font-weight: 400;
  font-family: "gotham-mediumgotham-medium";
}
a.text-linex {
 position: absolute;
  bottom: -20px;
  margin: auto;
  text-align: center;
  max-width: 700px;
  width: 90%;
  margin-top: 20px;
  font-size: 10
  px;
  font-weight: 200;
  font-family: "gotham-mediumgotham-medium";
  text-decoration: underline;
  color: #000000;
}
a.text-line {
  text-decoration: underline;
  color: #000000;
}

a.text-line:hover {
  text-decoration: underline;
  color: #000000;
}

.onboard-web2 .slick-next {
  position: absolute;
  bottom: 70px;
  top: auto;
  left: 40px;
  background: #000;
  text-align: center;
  width: 110px;
  height: 30px;
  border-radius: 30px;
  color: #fff;
  font-size: 14px;
  line-height: 30px;
  right: auto;
}

.onboard-web2 .slick-prev:before,
.onboard-web2 .slick-next:before {
  display: none;
}

.onboard-web2 .slick-prev {
  display: none !important;
}

.box-minheight {
  min-height: 300px;
  // padding: 40px 40px 0 50px;
}

.onboard-web2 .slick-next.slick-disabled {
  display: none !important;
}

.onboard-web2 .howtoplay-slide:after {
  content: "";
  position: absolute;
  left: -52px;
  top: 40%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 50px 70px;
  border-color: transparent transparent #ffffff transparent;
  transform: translate(0, -50%);
  z-index: -1;
}

.onboard-web2 a.btn-button {
  font-size: 16px;
  background: #f1666b;
  padding: 6px 30px;
  border-radius: 40px;
  line-height: 23px;
  color: #fff;
  margin-top: 20px;
  float: left;
}

.image-zoom .modal-dialog {
  position: relative;
  display: table;
  overflow: auto;
  width: auto;
  min-width: 300px;
}

.image-zoom .modal-content {
  border: 0;
  border-radius: 0;
  background: #fff;
}

.image-zoom .modal-body {
  border-radius: 0;
  border: 0;
}

.image-zoom .modal-body .image-container {
  overflow-y: auto;
}

.image-zoom .modal-body {
  overflow-y: auto;
}

/********************************************************/

@media (max-width: 991px) {

  .slick-next .slick-arrow {
    margin-top: 100px;
  }

  .onboard-web2 .howtoplay-slide p {
    font-size: 12px !important;
  }

  .big-content {
    margin-bottom: 20px;
  }

  .main.onboard-web-main {
    float: left !important;
    width: 100%;
  }

  .onboard-web-main .stand-man {
    left: -70px !important;
    width: 120px !important;
  }

  .onboard-web-main .howtoplay-slide {
    float: right;
    width: 90%;
    margin-left: 0;
    margin-top: 30px;
    margin-right: 10px;
    height: 60vh;
  }

  .howtoplay-slide h1 {
    font-size: 20px;
  }

  .howtoplay-slide p {
    font-size: 16px;
  }

  .howtoplay-slide .slick-list {
    border-radius: 0;
  }

  .howtoplay-slide {
    border-radius: 20px;
  }

  .howtoplay-slide .slick-slide .slide-inner {
    display: table !important;
  }

  .howtoplay-slide .hp-content {
    position: relative;
    display: table-cell;
    vertical-align: middle;
  }

  .howtoplay-slide .slick-dots li {
    margin: 0 6px;
  }

  a.btn-button {
    font-size: 12px;
    padding: 10px 30px;
  }

  .howtoplay-slide:after {
    left: -22px;
    top: 40%;
    border-width: 0 0 61px 90px;
  }

  /******/

  .stage-movement-page .stage-movement-main {
    height: 100%;
    overflow: hidden;
  }

  .main.stage-movement-main {
    float: left !important;
    width: 100%;
  }

  .stage-movement-main .main-wrapper {
    margin-left: 0;
  }

  .stage-movement-main .hand-mobile {
    position: fixed;
    bottom: 0;
    left: 50%;
    width: 310px;
    transform: translate(-50%);
    margin-left: 72px;
  }

  #stage-1 .content-stagemovement .stage-content {
    max-width: 280px;
    margin: 10px auto 0;
    padding: 0 20px;
  }

  .content-stagemovement p {
    font-size: 14px;
  }

  #stage-3 .content-stagemovement p {
    width: 220px;
    margin: 20px auto 0;
    font-size: 16px;
  }

  .click-btn {
    font-size: 11px;
  }
  .stage-movement-main .content-stagemovement {
    margin-left: 0 !important;
  }

  .book-block {
    width: 150%;
    margin-left: 30px;
    left: 100%;
  }

  .start-stage-btn {
    display: none;
  }

  .btn-next,
  .btn-next2 {
    display: block;
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 9;
    background: #fff;
    color: #000;
    border-radius: 20px;
    padding: 7px 15px 7px 15px;
    font-size: 12px;
    line-height: 21px;
    text-transform: uppercase;
  }

  .btn-next img.arrow-next2,
  .btn-next2 img.arrow-next2 {
    margin-left: 20px;
  }

  img.arrow-prev {
    width: 17px;
    margin-right: 10px;
    vertical-align: text-top;
  }

  .btn-prev,
  .btn-prev2 {
    display: block;
    position: fixed;
    bottom: 30px;
    left: 30px;
    z-index: 9;
    background: #fff;
    color: #000;
    border-radius: 20px;
    padding: 7px 25px 7px 15px;
    font-size: 12px;
    line-height: 21px;
  }

  #stage-3 .book-list li p {
    width: 220px;
    margin: 0 auto 20px;
    font-size: 11px;
    line-height: 16px;
    margin-bottom: 6px;
  }

  .hide-text {
    opacity: 0;
  }

  .show-text {
    opacity: 1;
  }

  #stage-3 {
    overflow: hidden;
  }

  .hand-mobile-slider .banner-content {
    position: relative;
    top: 0;
    width: 100vw;
    left: 0 !important;
  }

  .hand-mobile-slider .banner-content .hand-mobile {
    position: absolute;
  }

  .hand-mobile-slider .banner-content {
    position: relative;
  }

  .hand-mobile-slider .slick-slide .banner-content img {
    position: relative;
    right: 0;
    height: auto;
    padding: 0;
    width: 100% !important;
  }

  .hand-mobile-slider.slick-slider {
    margin-bottom: 0;
    bottom: 0;
    position: fixed;
    width: 100%;
  }

  .hand-mobile-slider .slick-slide > div,
  .hand-mobile-slider .slick-slide > div > div {
    height: 100vh;
  }

  .hand-mobile-slider .slick-list {
    height: 100%;
  }

  .hand-mobile-slider .banner-content {
    height: 70vh;
  }

  .hand-mobile-slider .slick-next {
    right: 0;
    z-index: 9;
  }

  .hand-mobile-slider .slick-prev {
    left: 0;
    z-index: 9;
  }

  .hand-mobile-slider .book-block {
    width: 100%;
    margin-left: 0;
    left: 0;
    position: absolute;
    bottom: 0;
    height: 76vh;
    top: auto;
  }

  .slick-track {
    width: 1000px;
    transform: translate3d(0px, 0px, 0px);
  }

  .hand-mobile-slider .slick-slide {
    opacity: 0;
  }

  .hand-mobile-slider .slick-slide.slick-current {
    opacity: 1;
  }

  .hand-mobile-slider .book-bg-1 {
    background: url(/images/book-half1.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 64vh;
    left: 12%;
    top: 30px;
  }

  .hand-mobile-slider .book-bg-1 .book-list {
    padding: 0;
    margin: 15%;
    list-style: none;
    width: 45%;
  }

  .hand-mobile-slider .book-bg-2 {
    background: url(/images/book-half2.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 64vh;
    left: auto;
    top: 30px;
    width: 90%;
  }

  .hand-mobile-slider .book-bg-2 .book-list {
    padding: 0;
    margin: 15% 15% 15% 36%;
    list-style: none;
    width: 45%;
  }

  .next-stage-slide p {
    color: #fff;
    width: 180px;
    margin: 0 auto;
    text-align: center;
    margin-top: 30px;
  }

  .hand-mobile-slider .slick-next {
    background: #fff;
    color: #000;
    border-radius: 20px;
    padding: 7px 15px 7px 15px;
    font-size: 12px;
    line-height: 15px;
    text-transform: uppercase;
    width: auto;
    height: auto;
    right: 20px;
    bottom: 10px !important;
    position: fixed;
    top: auto;
  }

  .hand-mobile-slider .slick-next span {
    background: url(/images/arrow-next2.png) no-repeat 0 0 / 100%;
    width: 19px;
    height: 14px;
    float: right;
    margin-top: 1px;
    margin-left: 20px;
  }
  .hand-mobile-slider .slick-prev:before,
  .hand-mobile-slider .slick-next:before {
    display: none;
  }

  .hand-mobile-slider .slick-prev {
    background: #fff;
    color: #000;
    border-radius: 20px;
    padding: 7px 15px 7px 15px;
    font-size: 12px;
    line-height: 15px;
    text-transform: uppercase;
    width: auto;
    height: auto;
    left: 20px;
    bottom: 10px !important;
    position: fixed;
    top: auto;
  }

  .hand-mobile-slider .slick-prev span {
    background: url(/images/arrow-prev2.png) no-repeat 0 0 / 100%;
    width: 19px;
    height: 14px;
    float: left;
    margin-top: 1px;
    margin-right: 20px;
  }

  .hand-mobile-slider .slick-prev.slick-disabled {
    display: none !important;
  }

  .stage-movement-page .section-wrapper {
    height: 100vh;
  }

  .onboard-web2 .box-slide {
    padding: 20px 20px 20px 20px;
    padding-bottom: 70px;
    margin-bottom: 15px;
  }

  .box-minheight {
    min-height: 300px;
    padding: 0px 20px 0 20px;
  }

  .onboard-web2 .howtoplay-slide p {
    font-weight: 400;
    margin-bottom: 10px;
    font-family: "gotham-mediumgotham-medium";
  }

  .onboard-web2 .howtoplay-slide .hp-content {
    width: 100%;
    max-width: 651px;
    height: 470px;
    position: relative;
    display: block;
  }

  .custom-onboard-height-1{
    height: 200px !important;
  }

  .custom-onboard-height-2{
    height: 320px !important;
  }

  .custom-onboard-height-3{
    height: 300px !important;
  }

  .custom-onboard-height-4{
    height: 320px !important;
  }

  .custom-onboard-height-5{
    height: 440px !important;
  }

  .custom-onboard-height-6{
    height: 290px !important;
  }

  .custom-onboard-height-7{
    height: 390px !important;
  }

  .custom-onboard-height-8{
    height: 300px !important;
  }

  .custom-onboard-height-9{
    height: 290px !important;
  }

  .custom-onboard-height-10{
    height: 290px !important;
  }



  .onboard-web2 .slick-next {
    position: absolute;
    bottom: 50px;
    top: auto;
    left: 80px;
    background: #000;
    text-align: center;
    width: 88px;
    height: 26px;
    border-radius: 30px;
    color: #fff;
    font-size: 12px;
    line-height: 27px;
  }


  .text-by {
    font-size: 10px;
  }

  .text-toknow {
    width: 66%;
    margin-top: -40px;
    font-size: 10px;
    margin-right: 40px;
    // left: 70px;
  }

  .onboard-web2 .slick-next {
    left: 40px;
  }

  .onboard-web2 .slick-slide img {
    width: 100% !important;
    height: auto;
    right: 0;
    position: static;
    padding-top: 0;
  }


  .onboard-web-main.onboard-web2 .howtoplay-slide {
    float: right;
    width: 90%;
    margin-left: 0;
    margin-top: 0;
    margin-right: 10px;
    // min-height: 68vh;
    height: auto;
  }


  .onboard-web-main.onboard-web2 .main-wrapper {
    padding: 0;
    position: relative;
    overflow: visible;
    margin-left: 15vw;
    width: 83%;
  }


  .onboard-web-main.onboard-web2 .slider > div {
    height: auto;
  }

  .custome-slide-inner-1{
    height: 220px !important;
  }

  .custome-slide-inner-2{
    height: 350px !important;
  }

  .custome-slide-inner-3{
    height: 310px !important;
  }

  .custome-slide-inner-4{
    height: 440px !important;
  }

  .custome-slide-inner-5{
    height: 220px !important;
  }

  // .custome-slide-inner-6{
  //   height: 350px !important;
  // }

  .custome-slide-inner-7{
    height: 260px !important;
  }

  .custome-slide-inner-8{
    height: 270px !important;
  }

  // .custome-slide-inner-9{
  //   height: 350px !important;
  // }

  .onboard-web-main.onboard-web2 .slick-track {
    height: auto;
  }

  .onboard-web2 .howtoplay-slide:after {
    left: -22px;
  }

  .onboard-web2 a.btn-button {
    font-size: 14px;
    padding: 6px 22px;
    line-height: 18px;
  }

  .image-zoom .modal-dialog {
    position: static;
    display: flex;
  }
}



@media (width: 768px) {
  .stage-movement-main .hand-mobile {
    position: fixed;
    bottom: 0;
    left: 55%;
    width: 520px;
  }

  .book-block {
    width: 130%;
    margin-left: 50px;
  }

  #stage-3 .book-list li p {
    font-size: 14px;
  }

  .book-list li .face-img {
    margin-right: 0;
    width: 14%;
  }

  .book-list li {
    margin-bottom: 30px;
  }

  #stage-3 .book-list li p {
    width: 80%;
    margin: 0 0 0 30px;
    font-size: 14px;
  }



}


@media (max-width: 767px) {
	.stage-movement-main .hand-mobile {
    bottom: -70px !important;
    width: 250px !important;
  }

 .image-one{
   max-width: 280px !important;
  }
  // .onboard-web2 .howtoplay-slide .hp-content{
  //   height: 440px !important;
  // }
  
  .onboard-web2 .slick-next{
    left: 0;
    right: 0;
    margin:0px auto;
  }
  .text-toknow{
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: inherit;
    text-align: center;
    float: none;
    // bottom: 40px;
    // margin: -30px auto -20px;
  }

  .text-by{
    // width: 93%; 
    text-align: center;
    // width: fit-content;
  }

  .onboard-web2 a.btn-button {
    font-size: 12px;
    padding: 6px 22px;
    line-height: 18px;
  }

  .howtoplay-slide h1 {
    font-size: 15px;
  }

  .onboard-web2 .howtoplay-slide p {
    font-weight: 400;
    margin-bottom: 10px;
    font-size: 14px;
  }

  p.error-text {
    color: red;
    font-size: 17px !important;
    font-style: italic;
    font-weight: 400;
    font-family: "gotham-mediumgotham-medium";
  }

  .check-block input[type="checkbox"] {
    width: 15px;
    height: 15px;
    margin-top: 2px;
  }

  .check-block .form-check-label {
    margin-left: 0px;
    font-size: 10px;
  }

  .check-block {
    float: left;
    margin-top: 1px;
  }

  .btm-div {
    margin-top: 20px;
  }

  .stage-movement-page .section-wrapper .container,
  .stage-movement-page .section-wrapper .container > div,
  .stage-movement-page .main-wrapper {
    height: 90% !important;
  }
  img.icon-ohno{
    width: 50%;
  }
}

@media (max-width: 620px) {
  .check-block{
    float: none !important;
    max-width: 150px;
    width: 100%;
    margin:20px auto;
  }

  .custom-onboard-height-10 {
    height: 390px !important;
}

  .custom-big-content {
    height: 460px !important;
  }

  a.btn-button.start-playing{
    float: none !important;
    margin: auto !important;
    width: 100%;
    max-width: 200px;
    text-align: center;
  }
}

@media (max-width: 620px) {
  .onboard-web-main.onboard-web2 .howtoplay-slide{
    // min-height: 60vh;
  }
}

@media (max-width: 440px) {
  .stand-man img{
    width: 75%;
  }
  img.pro-icon-img{
    width: 30px !important;
    right: 33% !important;
  }
  .text-toknow{
    max-width: 170px !important;
  }
  .onboard-web2 .howtoplay-slide p {
    font-size: 10px !important;
  }

  .custom-onboard-height-2{
    height: 420px !important;
}

  .custom-onboard-height-3{
    height: 330px !important;
  }

  .custome-slide-inner-3{
    height: 340px !important;
}

  .custome-slide-inner-4{
    height: 380px !important;
  }

  .custome-slide-inner-7{
    height: 300px !important;
  }

  .custome-slide-inner-8{
    height: 320px !important;
  }

  .custom-onboard-height-10 {
    height: 420px !important;
}
}

@media (max-width: 320px){
  .custom-big-content {
    height: 350px !important;
}
.custome-slide-inner-2 {
  height: 280px !important;
}
.button-footer button.btn.yes-redeem{
  font-size: 9px;
  max-width: 90px;
}
}

@media (max-width: 580px){
  .custom-onboard-height-7 {
    height: 450px !important;
  }

  .custom-onboard-height-10 {
    height: 430px !important;
}

.custom-onboard-height-3 {
  height: 370px !important;
  }
}

@media (max-width: 340px) {
  #stage-3 .content-stagemovement p {
    margin: 20px auto 0;
  }

  .stage-movement-main .hand-mobile {
    width: 280px;
    margin-left: 62px;
  }

  .book-block {
    width: 140%;
  }

  .onboard-web2 .howtoplay-slide:after {
    left: -16px;
  }

  .onboard-web2 .howtoplay-slide p {
    font-size: 8px !important;
  }

  .custom-onboard-height-2 {
    height: 350px !important;
}
}

/************************Slider********************************/

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

/********************onboard********************************/
.check-block {
  float: left;
  margin-top: 6px;
}

.check-block input[type="checkbox"] {
  width: 20px;
  height: 20px;
  margin-top: 2px;
}

.check-block .form-check-label {
  margin-left: 10px;
  font-size: 17px;
}

a.btn-button.start-playing {
  float: right;
  margin-top: 0 !important;
}

p.error-text {
  color: red;
  font-size: 17px !important;
  font-style: italic;
  font-weight: 400;
  font-family: "gotham-mediumgotham-medium";
}

.btm-div {
  margin-top: 40px;
}

.img-responsive {
  width: 100%;
  display: block;
  margin: auto;
  height: auto;
}

.image-one{
  max-width: 359px;
}

.image-two{
  max-width: 356px;
}
/******************************************************/
/********************   INVESTMENT END  ************************/

/********************   CUBE LOADER   ************************/

body {
  padding: 0;
  margin: 0;
}

#cube .front {
  transform: translateZ(100px);
}

#cube .back {
  transform: rotateX(-180deg) translateZ(100px);
}

#cube .right {
  transform: rotateY(90deg) translateZ(100px);
}

#cube .left {
  transform: rotateY(-90deg) translateZ(100px);
}

#cube .top {
  transform: rotateX(90deg) translateZ(100px);
}

#cube .bottom {
  transform: rotateX(-90deg) translateZ(100px);
}

.cube-container {
  width: 200px;
  height: 200px;
  position: relative;
  position: absolute;
  top: 48%;
  left: 48%;
  margin: 0 auto;
  border: 0px solid #fff;
  perspective: 1000px;
  perspective-origin: 50% 100%;
}

#cube {
  width: 100%;
  height: 100%;
  position: absolute;
  transform-style: preserve-3d;
  transition: transform 4s;
  animation: rotate 14s infinite;
}


#cube:hover {
  cursor: pointer;
}

#cube div {
  background: hsla(296, 28%, 65%, 0.6);
  display: block;
  position: absolute;
  width: 200px;
  height: 200px;
  border: 2px solid #bc8cbf;

  margin: 0 auto;

  font-family: Arial, Helvetica, sans-serif;
  font-size: 500%;
  text-align: center;
  padding: 50px 0;
}

.dot {
  display: block;
  position: absolute;
  width: 30px;
  height: 30px;
  background: #fff;
  border-radius: 15px;
}

.front .dot1 {
  top: 85px;
  left: 85px;
}
.back .dot1 {
  top: 45px;
  left: 45px;
}
.back .dot2 {
  top: 125px;
  left: 125px;
}
.right .dot1 {
  top: 45px;
  left: 45px;
}
.right .dot2 {
  top: 85px;
  left: 85px;
}
.right .dot3 {
  top: 125px;
  left: 125px;
}
.left .dot1 {
  top: 45px;
  left: 45px;
}
.left .dot2 {
  top: 45px;
  left: 125px;
}
.left .dot3 {
  top: 125px;
  left: 45px;
}
.left .dot4 {
  top: 125px;
  left: 125px;
}
.top .dot1 {
  top: 45px;
  left: 45px;
}
.top .dot2 {
  top: 45px;
  left: 125px;
}
.top .dot3 {
  top: 85px;
  left: 85px;
}
.top .dot4 {
  top: 125px;
  left: 45px;
}
.top .dot5 {
  top: 125px;
  left: 125px;
}
.bottom .dot1 {
  top: 45px;
  left: 45px;
}
.bottom .dot2 {
  top: 45px;
  left: 85px;
}
.bottom .dot3 {
  top: 45px;
  left: 125px;
}
.bottom .dot4 {
  top: 125px;
  left: 45px;
}
.bottom .dot5 {
  top: 125px;
  left: 85px;
}
.bottom .dot6 {
  top: 125px;
  left: 125px;
}

@keyframes rotate {
  25% {
    transform: rotateX(-355deg) rotateY(-725deg) rotateZ(0deg);
  }

  50% {
    transform: rotateX(725deg) rotateY(-455deg) rotateZ(0deg);
  }

  100% {
    transform: rotateX(1445deg) rotateY(1705deg) rotateZ(0deg);
  }
}

/********************   CUBE LOADER END  ************************/

/*************************************************************/

.flyout-menu {
  position: fixed;
  right: 0;
  top: 0;
  width: 337px;
  height: 100%;
  background: #4d4d4d;
  z-index: 99;
  border-radius: 50px 0 0 0;
  color:#ffffff;
  padding: 30px 30px;
  // display:none
}

.close-icon {
  color:#ffffff;
  display:block;
  margin-bottom: 30px;
  cursor:pointer
}

.flyout-wrapper {
  font-size: 16px;
}
.flyout-wrapper h3{
  font-size: 16px;
}

.flyout-wrapper .signout {
  margin: 30px 0;
  padding: 20px 0;
  border-top: 1px solid;
  border-bottom: 1px solid;
  color:#ffffff;
}

.flyout-wrapper .signout a {
  color:#ffffff;
  text-transform:uppercase;
}

.flyout-wrapper  p{
  font-family: 'gotham-mediumgotham-medium';
  margin:30px 0;
  display: inline-block;
  width: 100%;
}

.flyout-wrapper .signout a:hover {
  text-decoration:none
}

.flyout-menu .flyout-height {
  height:100%;
  width: 100%;
}

.flyout-bottom {
  display: table-footer-group;
  vertical-align: bottom;
  width: 100%;
  font-family: 'gotham-mediumgotham-medium';
}

.flyout-bottom em{
  font-family: 'gotham-mediumgotham-medium';
}


a.text-link {
  color:#ffffff;	
  text-decoration:underline;
}

a.text-link:hover {
  color:#ffffff;	
  text-decoration:underline;
}

.share-icons{
  margin: 30px 0;
  padding: 20px 0;
  border-top: 1px solid;
  border-bottom: 1px solid;
}

.share-icons label {
  margin-bottom: 10px;
  font-size: 17px; font-family: 'gotham-mediumgotham-medium';
}

.share-icons a{
  background: url("/images/share-icons.png") no-repeat 0 0 / 160px;
  width:30px;
  height:30px;
  text-indent:-9999px;
  float:left;
  margin: 0 6px;
}

.share-icons a.mail { 
  background-position:0 0;
  margin-left:0
}

.share-icons a.fb { 
  background-position:-44px 0
}

.share-icons a.in { 
  background-position:-87px 0
}

.share-icons a.tw { 
  background-position:-128px 0
}


.op-0{
  pointer-events: none;
  opacity: 0.5;
}

/***********View Pending Tasks Button***********/

.bkg-lightpurple {
	background:#bc8cbf
}

img.icon-left {
  margin-top: -20px;
  margin-right: 9px;
  margin-bottom: -20px;
}

img.icon-left.icon-view-star {
	width:25px;
	margin-right: 8px;
}

@media (max-width:991px) {
  img.icon-left {
		margin-top: -20px;
		margin-right: 2px;
		margin-bottom: -20px;
		width: 50px;
  }

  // .next-stage-btn.view-pendingtasks {
  //     position: absolute;
  //     top: 64vh;
  //     clear: both;
  //     left: 8%;
  //     width: 92%;
  // }

  // .next-stage-btn.view-pendingtasks {
  //     line-height: 40px;
  //     font-size: 3vw;
  // }
}

@media (max-width: 480px){
  img.icon-left {
		width: 30px;
  }
  
  // .next-stage-btn.view-pendingtasks {
  //     position: absolute;
  //     top: 57vh;
  // }
}

span.count-icon {
  position: absolute;
  z-index: 1;
  margin-top: -35px;
  margin-left: -5px;
  background: url(/images/count-icon.png) no-repeat 0 0 / 100%;
  width: 35px;
  height: 35px;
  text-align: center;
  line-height: 31px;
  padding-left: 3px;
  font-size: 17px;
  color: #fff;

}

@media (max-width:991px) {
  img.icon-left {
		margin-top: -20px;
		margin-right: 2px;
		margin-bottom: -20px;
		width: 50px;
  }

  // .next-stage-btn.view-pendingtasks {
  //     position: absolute;
  //     top: 64vh;
  //     clear: both;
  //     left: 8%;
  //     width: 92%;
  // }

  // .next-stage-btn.view-pendingtasks {
  //     line-height: 40px;
  //     font-size: 3vw;
  // }
}

@media (max-width: 480px){
  img.icon-left {
		width: 30px;
  }
  
  // .next-stage-btn.view-pendingtasks {
  //     position: absolute;
  //     top: 57vh;
  // }
}

@media (width: 768px) {
	span.count-icon {
		margin-top: -55px;
	}
}

/**********************Contextual Tour**************************/
.contextual-header{
  position: absolute;
  top: 0;
  width: 100%;
}

.contextual-container{
  position: absolute;
  top: 100px;
}

@media (max-width: 768px) {
	.contextual-container{
    top: 70px;
  }
}

.contextual-oflow {
	overflow:hidden;
}

.overlay-black {
	position: fixed;
  background: rgba(0,0,0,0.8);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 4;
}

.contextual-box {
	background: #ffffff;
  border-radius: 20px;
  position: absolute;
  max-width: 600px;
  width: 90%;
  z-index: 6;
  padding: 20px;
}

.contextual-banner{
  height: 80%;
  width: 100%;
  margin-top: 50px;
}

.contextual-next-btn{
  position: relative;
  bottom: 34px;
}

.contextual-box h3 {
  font-size: 18px;
}

a.btn:focus {
  outline: 0 none;
  border: 0;
  box-shadow: none !important;
}

.contextual-box p {
	font-family: 'gotham-mediumgotham-medium';
	font-size: 16px;
}

.contextual-box p {
  margin-bottom: 10px;
}

.ct-heading {
	margin-bottom:20px
}

.skip-b {
	color:#7d7d7d;
}

.skip-b:hover {
	color:#7d7d7d;
}

.ct-flex-btn .btn {
  border: 0;
  border-radius: 10px;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 34px;
  height: 34px;
  padding: 0 40px;
}

.btn-pink {
  color: #fff;
  background-color: #f1666b; 
	text-transform:uppercase;
}

.btn-pink:hover {
	color:#ffffff;
}

.ct-flex-btn {
	justify-content: space-between;
	margin: 10px 0 0;
}

.content-context {
  padding: 12px;
}

// .contextual.left-nav-menu:before { //disables the left menu
//   content: "";
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   top: 0;
//   left: 0;
//   background: transparent;
//   z-index: 5;
// }

@media (max-width:991px){
	.content-context {
		padding: 0 0px 0 10px;
		width: 180%;
	}
	
	.content-context {
		padding: 0;
		width: 180%;
	}
	
	.content-context p {
		font-size: 12px;
	}
	
	.ct-flex-btn .btn {
		font-size: 14px;
		line-height: 32px;
		height: 30px;
		padding: 0 24px;
	}
}

@media (max-width:480px){
	.content-context p {
		font-size: 3.3vw;
	}
}

/************Tour-1*****************/

.man-bkg {
	left:50%;
	top:50%;
	transform:translate(-50%,-50%);
	position: absolute;
	z-index: 5;
}

.pos1{
	left:50%;
	top:50%;
	transform:translate(-50%,-50%)
}

.contextual-box h3 {
  font-size: 18px;
}

.ct-left-img img {width:100%;}

.contextual-box p {
  margin-bottom: 10px;
}

.ct-left-img {
  text-align: center;
	width: 50%;
}

.ct-left-img img.ten-stage{
	width:70%
}

.ct-heading {
	margin-bottom:20px
}

.content-context.ct-right {
  padding: 0 30px;
}

.ct-flex-btn {
	justify-content: space-between;
	margin: 10px 0 0;
}

.content-context {
  padding: 12px;
}

@media (max-width:991px){
	.content-context {
		padding: 0 0px 0 10px;
		width: 180%;
	}
	
	.pos1 .content-context p{font-size:2.5vw}
	
	.content-context {
		padding: 0;
		width: 180%;
	}
	
	.content-context p {
		font-size: 12px;
	}
	
	.contextual-box.pos2 {
		max-width: 240px;
		top: 50%;
		left: 4%;
	}
	
	.pos2 .ct-content-wrapper {
		padding-top: 30px;
	}
	
	.ct-flex-btn .btn {
		font-size: 14px;
		line-height: 32px;
		height: 30px;
		padding: 0 24px;
	}
}

@media (width: 768px){
	.pos1 .content-context p {
		font-size: 18px;
	}

	.man-bkg img {
		max-width: 100%;
		width: 300px;
	}
  
  .smm-img-left img.smm-bubble{
    width: 95%;
  }

  #stage-2{
    height: 90%;
  }

  .stage-movement-page .section-wrapper .container, .stage-movement-page .section-wrapper .container > div, .stage-movement-page .main-wrapper{
    height: 94%;
  }
}


/************Tour-2*****************/
.contextual-box.pos2 {
  max-width: 310px;
  top: 50%;
  left: 11%;
}
	
.pos2-face {
  position: absolute;
  width: 50%;
  top: -90px;
  left: 10px;
}

.pos2 .ct-content-wrapper {
  padding-top: 50px;
}

.tour2.slider { // highlights the carousel section
  z-index: 4 !important;
  background: #fdca36 !important;
}

// .tour2.slider.slick-dotted.slick-slider:before {
// 	content: "";
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   top: 0;
//   left: 0;
//   background: transparent;
//   z-index: 5;
// }

.pos2 .content-context {padding:0}

.contextual.slider .slick-dots {display:none !important}  //Removes the silders dots

.main.trans {
  border-radius: 0;
}

@media (max-width:991px){
	.contextual-box.pos2 {
		max-width: 240px;
		top: 50%;
		left: 4%;
	}
	
	.pos2 .ct-content-wrapper {
		padding-top: 30px;
	}
}

// /************Tour-3*****************/
.contextual-box.pos3 {
  max-width: 310px;
  top: 20%;
  left: 27%;
}

.pos3-face {
	position: absolute;
  width: 50%;
  top: -120px;
  left: -30px;
}

.pos3 .ct-content-wrapper {
  padding-top: 30px;
}

.tour3.left-nav-menu li:first-child { //highlights but changes the colour to yellow in the dashboard
  z-index: 4;
  position: relative;
  background: #fdca36;
  padding-right: 30px;
  padding-top: 12px;
  margin-right: -30px;
}

.pos3 .content-context {padding:0}

.main.trans {
  border-radius: 0;
}

@media (max-width:991px){	
	.contextual-box.pos3 {
		max-width: 210px;
		top: 30%;
		left: 39%;
	}

	.pos3 .ct-content-wrapper {
		padding-top: 20px;
	}

	.pos3-face {
		position: absolute;
		width: 50%;
		top: -80px;
		left: -12px;
	}
}

/************Tour-4*****************/
.contextual-oflow {
	overflow:auto;
}

.contextual-box.pos4 {
  max-width: 600px;
  top: 25%;
  left: 27%;
}
	
.pos4-face {
  position: absolute;
  width: 180px;
  top: -120px;
  left: -30px;
}

.pos4 .ct-content-wrapper {
  padding-top: 50px;
}

/* hightlights the left menu except Life Events */

// .tour4.left-nav-menu a { //not required
//   background: #fff;
// }

.tour4.left-nav-menu li:first-child {
  z-index: 3;
  position: relative;
}

.tour4.left-nav-menu ul li:not(:first-child) {
  z-index: 4;
  position: relative;
  background: #fff;
  padding-right: 0;
  padding-top: 0;
  margin-right: 0;
}
/* hightlights the left menu except Life Events */

.ct-content-wrapper p b {color:#f1666b}

.pos4 .content-context {padding:0}

.main.trans {
  border-radius: 0;
}

@media (max-width:991px){
	.contextual-oflow {
    overflow:hidden;
  }
	
	.contextual-box.pos4 {
    max-width: 240px;
    top: 30%;
    left: 29%;
	}
	
	.pos4 .ct-content-wrapper {
		padding-top: 20px;
	}

	.pos4-face {
    position: absolute;
    width: 50%;
    top: -80px;
    left: -12px;
	}

	.content-context p {
		font-size: 10px;
	}
}

/************Tour-5*****************/
.contextual-oflow {
	overflow:auto;
}

.contextual-box.pos5 {
  max-width:600px;
  top: 56%;
  right: 27%;
}
	
.pos5-face {
  position: absolute;
  width: 180px;
  top: -90px;
  right: -30px;
}

.pos5 .ct-content-wrapper {
  padding-top: 50px;
}

.tour5.ic-right-wrap { // highlights the finance summary
  z-index: 4;
  position: relative;
  background: #fdca36;
  border-radius: 0 !important;
}

.ct-content-wrapper p b {color:#f1666b}

.pos5 .content-context {padding:0}

.main.trans {
  border-radius: 0;
}

@media (max-width:991px){
	.contextual-oflow {
    overflow:hidden;
  }
	
	.contextual-box.pos5 {
    max-width: 240px;
    top: 31%;
    left: 9%;
    right:auto;
	}
	
	.pos5 .ct-content-wrapper {
		padding-top: 20px;
	}

	.pos5-face {
		position: absolute;
		width: 50%;
		top: -80px;
		left: -12px;
	}

	.content-context p {
		font-size: 10px;
	}

	.for-mobile .tour5.ic-right-wrap li.ic-list {
    padding: 8px 16vw 5px 7vw;}

	.tour5.ic-right-wrap {
		padding-top: 20px !important;
		margin-top: -20px;
		width: 70% !important;
	}
}

/************Tour-6*****************/

.contextual-oflow {
	overflow:auto;
}

.pos6-face {
	position: absolute;
	width: 180px;
  top: -90px;
  right: -30px;
}

.pos6 .ct-content-wrapper {
  padding-top: 50px;
}

.tour6.profile-car { // highlights the profile part
  padding: 15px 10px 0;
  z-index: 5;
  position: relative;
}

.contextual-box.pos6 {
  max-width: 380px;
  top: 31%;
  right: 27%;
}

// .right-side:after { // box around highlight - not required
// 	content: "";
//   background: #fdca36;
//   position: absolute;
//   left: -10px;
//   top: 0;
//   width: 95%;
//   height: 17%;
//   z-index: 4;
// }

.pos6 .content-context {padding:0}

.main.trans {
  border-radius: 0;
}

@media (max-width:991px){
	.contextual-oflow {
    overflow:hidden;
  }

	.contextual-box.pos6 {
		max-width: 240px;
		top: 27%;
		left: 9%;
		right:auto;
	}

	.pos6 .ct-content-wrapper {
		padding-top: 20px;
	}

	.pos6-face {
		position: absolute;
		width: 50%;
		top: -80px;
		left: 68px;
	}

	.content-context p {
		font-size: 10px;
	}

	.for-mobile .ic-right-wrap li.ic-list {
    padding: 8px 16vw 5px 7vw;}

	.ic-right-wrap {
		padding-top: 20px !important;
		margin-top: -20px;
		width: 70% !important;
	}

	.tour6.profile-car span.number-count {
		top: 0;
	}

	.tour6.profile-car span.number-count b {
		font-size: 5VW;
	}

	// .mobile-row .left-side:after { //box around hightlight - not required
	// 	content: "";
	// 	background: #fdca36;
	// 	position: absolute;
	// 	right: -10px;
	// 	top: 53px;
	// 	width: 35%;
	// 	height: 14%;
	// 	z-index: 4;
  // }
}

/************Tour-7*****************/

.contextual-box.pos7 {
	max-width: 390px;
  bottom: 31%;
  left: 27%;
  padding-bottom: 60px;
}
	
.pos7-face {
  position: absolute;
  width: 40%;
  bottom: -100px;
  left: -100px;
}

.pos7 .ct-content-wrapper {
  padding-top: 0;
}

/* hightlights next stage button and Life Events with a tick */
.tour7.left-nav-menu li:first-child {
  z-index: 4;
  position: relative;
	background: #fdca36;
	padding-right: 30px;
  padding-top: 12px;
  margin-right: -30px;
}

.tour7.next-stage-btn {
  z-index: 4;
  position: relative;
  background: #fdca36;
  border-radius: 0;
  padding: 20px;
  // margin-top: -20px;
}

.tour7.next-stage-btn span {
  display: block;
  background: #f1666b;
  border-radius: 30px;
}
/* hightlights next stage button and Life Events with a tick */

.pos7 .content-context {padding:0}

.main.trans {
  border-radius: 0;
}

.next-stage-btn .icon-left {
  margin-top: -20px;
  margin-bottom: -20px;
}

// .main-wrapper:after {
// 	content: "";
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   top: 0;
//   left: 0;
//   background: transparent;
//   z-index: 5;
// }

@media (max-width:991px){
	.contextual-box.pos7 {
    max-width: 250px;
    top: 17%;
    left: 39%;
    width: 57%;
    bottom: auto;
	}

	.pos7 .ct-content-wrapper {
		padding-top: 0;
	}

	.pos7-face {
    position: absolute;
    width: 50%;
    bottom: -70px;
    left: -12px;
	}

	.tour6.next-stage-btn {
		font-size: 3vw;
	}
}

/************Tour-8*****************/
.contextual-oflow {
	overflow:auto;
}

.pos8-face {
	position: absolute;
  width: 180px;
  top: -80px;
  right: 40px;
}

.pos8 .ct-content-wrapper {
  padding-top: 0;
}

.contextual-box h3 {
  font-size: 18px;
  padding-bottom: 40px;
}

.contextual-box.pos8 {
  max-width: 380px;
  bottom: 4%;
  right: 27%;
}

.tour8.right-bottom-man { // highlights bugs
  position: relative;
  z-index: 4;
  background: #fdca36;
}

.pos8 .content-context {padding:0}

.main.trans {
  border-radius: 0;
}

@media (max-width:991px){
	.contextual-oflow {
    overflow:hidden;
  }

	.contextual-box.pos8 {
		max-width: 240px;
		top: auto;
		left: 31%;
		bottom: 10%;
	}

	.pos8 .ct-content-wrapper {
		padding-top: 0;
	}

	.pos8-face {
		position: absolute;
		width: 45%;
		top: -54px;
		right: 0;
		left: auto;
	}

	.content-context p {
		font-size: 10px;
  }

  // highlights bugs
	.tour8.bottom-mobile {
		z-index: 4;
		position: relative;
		background: #fdca36;
		width: 110px;
	}

	.contextual-box h3 {
		padding-bottom: 10px;
	}
}

/*********Profile-popup****************/

.profile-popup .modal-content {
	background:#ffffff;
	border-color:#fdc627;
	padding: 20px;
}

.profile-popup:before {
  content: "";
  display: block;
  position: fixed;
  background: rgba(0,0,0,0.8);
  width: 100%;
  height: 100%;
}

.profile-popup .modal-body {
  border-radius: 0;
  overflow: visible;
}


.pp-left-man {
	width: 25%;
	padding: 10px;
}


.pp-right-main {
  width: 75%;
}

.profile-nick-name {
	border-bottom:1px solid #000;
	margin-bottom: 20px;
}

.pp-left-man img {
	width:100%
}

.pp-left p {
  margin-bottom: 30px;
}

.pp-left {text-align: center;
  padding-right: 20px;
  border-right: 1px solid #000; width: 30%;
}

.pp-right {
  width: 70%;
  padding: 0 20px;
  text-align: center;
}

.pp-right p {margin:0}

.pp-right em {font-size:13px}

.pp-left img {
  width: 100%;
}

.profile-popup .modal-body p {
  font-size: 16px;
  font-family: "gotham-mediumgotham-medium";
}

.pp-assets-list ul {
	padding:0; list-style:none
}

.pp-assets-list ul li{ 
  float: left;
  width: 26.5%;
  margin: 0px 4%;
}

.pp-assets-list ul li.m0 {
	margin-right:0
}

.profile-popup .img-number img {
	width:100%
}

.profile-popup .img-number {
	position:relative;
}

span.pp-count {
  position: absolute;
  right: 9%;
  top: 5%;
}

span.pp-count {
  position: absolute;
  background: url(/images/count-icon2.png) no-repeat 0 0 / 100%;
  width: 28px;
  height: 28px;
  text-align: center;
  line-height: 25px;
  padding-left: 2px;
  font-size: 14px;
  color: #fff;
  right: 0%;
  top: 11%;
}

.pp-assets-list p.assets-text {
	font-size: 14px;
  line-height: 16px;
	margin: 8px 0 20px;
}


@media (min-width: 576px) {
	.profile-popup .modal-dialog {
		max-width: 1000px;
	}
}

@media (max-width: 991px) {
	.profile-popup .modal-body p {
    font-size: 13px;}
	
	.profile-popup  .bugs-content {
		padding-right: 13%;
	}

	.profile-popup  .bugs-content .button-footer {
		padding-right: 33%;
	}

	.profile-popup .modal-dialog {
		margin: 0 20px;
	}

	.profile-popup h2.title-heading-name {
		font-size: 15px;
		margin-bottom: 10px;
		padding-bottom: 0;
	}
	
	.profile-name-block{
		width: 100%;
		border-bottom: 1px solid #000;
    margin-bottom: 10px;
    padding-bottom: 10px;
    align-items: flex-end;
	}
	
	.pp-right-main{
		width: 100%;
	}
	
	.pp-left-man-mobile {
    max-width: 70%;
    padding: 0;
    margin-top: -80px;
	}
	
	.pp-left-man-mobile img {width:100%}
	
	.profile-nick-name {
		border-bottom: 10px;
	}
	
	.pp-content.d-flex {
		display: block !important;
	}
	
	.pp-left {    width: 100%;
    flex-direction: row-reverse;
    display: flex;
    padding: 0;
    border: 0;
    border-bottom: 1px solid #000;
    padding-bottom: 10px; align-items: center;
  }
  
  .pp-right {
    width:100%;
    padding: 4px 0 0;}

	.pp-left img {
    max-width: 40%;
    padding-right: 5%;
	}
	
	.pp-left p {
		width:60%;
		text-align: left;
	}
	
	.pp-assets-list ul li {
		float: left;
		width: 28.5%;
		margin: 0px 2%;
	}
	
	.pp-assets-list ul li.m0 {
		margin-right: 2%;
	}
	
	.pp-assets-list p.assets-text {
		font-size: 11px !important;
		line-height: 12px;
		margin: 0 0 10px;
	}
	
	.profile-popup .modal-content {
		padding: 10px;
	}
	
	.profile-popup .modal-content {
		margin-top: 60px;
	}
	
	.pp-assets-list ul {
		padding: 0;
		list-style: none;
		margin: 0;
	}
}

@media (width: 768px){
	.pp-left-man-mobile {
    width: 30%;}
	
	.profile-popup .modal-body p {
		font-size: 22px;
	}
	
	.pp-assets-list p.assets-text {
		font-size: 20px !important;
		line-height: 23px;
		margin: 0 0 10px;
	}
	
	.pp-right {
		padding: 10px 0 0;
	}

}

@media (max-width: 768px){
  .paylater-tooltip {
    left: 27%;
    // top: 14%;
}
}
/*************************Already Shopped************************/
.shop-list ul{
	padding:0;
	list-style:none;
}

.shop-list ul li{
	display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100px;
    border-bottom: 1px solid #f1666b;
    padding: 10px 0;
    width: 100%;
}

.shop-list ul li span{
	width:20%;
}

.shop-list ul li span img {
	width:100%
}

.shop-list ul li p{
	width: 80%;
    margin: 0;
    padding-left: 30px;
	font-family: 'gotham-mediumgotham-medium';
	font-size: 16px;
}

@media (max-width:991px) {	
	.shop-list ul li {
		display: flex;
		align-items: center;
		justify-content: center;
		min-height: 70px;
		border-bottom: 1px solid #f1666b;
		padding: 10px 0;
		width: 100%;
	}
	
	.shop-list ul li p{
		padding-left: 20px;
		font-size: 14px;
  }
}

.fin-summary .modal-content {
	background:#ffffff;
	border-color:#f1666b;
	padding: 40px;
}

.overlay:before {
    content: "";
    display: block;
    position: fixed;
    background: rgba(0,0,0,0.8);
    width: 100%;
    height: 100%;
}

.fin-summary .modal-body {
    border-radius: 0;  }


.table-smm ul li {display:flex; border-bottom: 1px solid #ffcc08}

.table-smm ul li > div {
	width:33%;
	padding: 6px 12px 3px;
	    text-align: left;
}

.fs-table-list.table-smm ul li > div.left-col {
	width: 75%;
}

.fs-table-list.table-smm ul li > div.right-col {
	width: 25%;
	padding-right: 0;
}

.fs-table-list.table-smm ul li > div {
	font-size: 16px;
	padding: 3px 9px 3px 0;
}

.fs-table-list.table-smm ul li {
	border-bottom:1px solid #edc276;
}

.fs-table-list.table-smm ul li.nonborder {
	border:0
}


.fs-table-list.table-smm ul li.start-balance {
	margin-bottom:10px
}

.fs-table-list.table-smm ul li.fbs-heading {
	color:#f1666b;
	font-size: 16px;
	text-transform:uppercase;
	border:0;
	    padding-bottom: 6px;
}

.fs-table-list ul li.space {height: 20px;
    border: 0 !important;}

.fs-table-list em {
    display: block;
    font-size: 12px;
}

.fs-table-list em.red-text {color:#ff0000;}

.fins-heading{
	    color: #f1666b;
    font-size: 22px;
    border-bottom: 1px solid #f1666b;
    padding-bottom: 10px;
}

.icon-red {
	background:url("/images/red-icon.png") no-repeat 0 0 / 100%;
	    width: 16px;
    height: 16px;
    margin-left: 7px;
    display: inline-block;
    position: relative;
    top: 3px;
}

.btn.btn-pd {
    padding: .375rem 2rem;
}

.table-smm.fs-table-list {
    max-height: 70vh;
    overflow-y : auto;
}

@media (min-width: 576px) {
	.fin-summary .modal-dialog {
		max-width: 600px;
	}
}

@media (max-width:768px){
	.fs-table-list.table-smm ul li.fbs-heading .left-col {
		font-size: 4vw;
	}
	
	.fs-table-list.table-smm ul li > div {
		font-size: 3.5vw;
	}

	.fin-summary .modal-content {
		padding: 20px;
	}

	.fins-heading {
    font-size: 5vw;}

	.fs-table-list.table-smm ul li > div.right-col {
		width: 30%;
	}

	.fs-table-list.table-smm ul li > div.left-col {
		width: 70%;
	}

	.fs-table-list em {
		font-size: 2.5vw;
	}
}

@media (max-width: 380px) {
	.fs-table-list.table-smm ul li > div.right-col {
		width: 35%;
	}
}